/**
 * Provide grid.
 */

@mixin grid {
  gap:
    var(--grid-gap-row, #{$grid-gap-row})
    var(--grid-gap-column, #{$grid-gap-column})
  ;
  grid: {
    template: {
      columns: repeat(
        var(--grid-sizing, #{$grid-sizing}),
        minmax(
          #{"min(100%, var(--grid-column-min, #{$grid-column-min}))"},
          var(--grid-column-max, #{$grid-column-max})
        )
      );
    }
  }
}
