/**
 * Component Cursor.
 */

// =============================================================================
// Cursor
// =============================================================================

// .cursor {}

/**
 * Modifiers.
 */

.cursor {
  @each $type in $cursor-types {
    &.--#{$type} {
      cursor: $type;
    }
  }
}
