/**
 * Component Form/Select settings.
 */

// =============================================================================
// Form select settings
// =============================================================================

// Set form select indent (according to `$form-element-indent`).
$form-select-indent: 1em;

// Set form select control indent (according to `$form-element-indent`).
$form-select-control-indent: 1em;

// Set form select control size.
$form-select-control-size: 1em;
