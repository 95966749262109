/**
 * Component Button.
 *
 * @requires {function} px-to-rem
 */

// =============================================================================
// Button
// =============================================================================

.button {
  @extend %element--medium !optional;
  @extend %element--size !optional;

  --background-color: #{map-get($button-background, initial)};
  --border-color: #{map-get($button-border, initial)};
  --color: #{map-get($button-color, initial)};

  align-items: center;
  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  color: var(--color);
  cursor: pointer;
  display: inline-flex;
  font: {
    weight: 500;
  }
  justify-content: center;
  max-width: 100%;
  padding: 0 $button-indent;
  text: {
    decoration: none;
    // transform: capitalize;
  }
  transition: {
    duration: 0.2s;
    property:
      background-color,
      border-color,
      color,
      filter,
    ;
  }
  user-select: none;
  white-space: nowrap;
}

/**
 * Modifiers.
 */

/**
 * Button, narrow.
 * TODO: Implement lengths.
 */

.button {
  &.--narrow {
    $indent: 0.75 * $button-indent;

    &[class] {
      padding: {
        left: $indent;
        right: $indent;
      }
    }
  }
}

/**
 * Button, wide.
 * TODO: Implement lengths.
 */

.button {
  &.--wide {
    $indent: 3 * $button-indent;

    &[class] {
      padding: {
        left: $indent;
        right: $indent;
      }
    }
  }
}

/**
 * Primary.
 */

.button {
  &.--primary {
    --background-color: #{map-get($button--primary-background, initial)};
    --border-color: #{map-get($button--primary-border, initial)};
    --color: #{map-get($button--primary-color, initial)};
  }
}

/**
 * Secondary.
 */

.button {
  &.--secondary {
    --background-color: #{map-get($button--secondary-background, initial)};
  }
}

/**
 * Danger.
 */

.button {
  &.--danger {
    --background-color: #{map-get($button--danger-background, initial)};
    --border-color: #{map-get($button--danger-border, initial)};
    --color: #{map-get($button--danger-color, initial)};
  }
}

/**
 * Success.
 */

.button {
  &.--success {
    --background-color: #{map-get($button--success-background, initial)};
    --border-color: #{map-get($button--success-border, initial)};
    --color: #{map-get($button--success-color, initial)};
  }
}

/**
 * Warning.
 */

.button {
  &.--warning {
    --background-color: #{map-get($button--warning-background, initial)};
    --border-color: #{map-get($button--warning-border, initial)};
    --color: #{map-get($button--warning-color, initial)};
  }
}

/**
 * States.
 */

/**
 * Button, active.
 */

.button {
  &.is-active,
  &:active {
    &:not(:disabled):not(.is-disabled) {
      --background-color: #{map-get($button-background, active)};
      --border-color: #{map-get($button-border, active)};
      --color: #{map-get($button-color, active)};

      &.--primary {
        --background-color: #{map-get($button--primary-background, active)};
        --border-color: #{map-get($button--primary-border, active)};
        --color: #{map-get($button--primary-color, active)};
      }
      &.--danger {
        --background-color: #{map-get($button--danger-background, active)};
        --border-color: #{map-get($button--danger-border, active)};
        --color: #{map-get($button--danger-color, active)};
      }
      &.--success {
        --background-color: #{map-get($button--success-background, active)};
        --border-color: #{map-get($button--success-border, active)};
        --color: #{map-get($button--success-color, active)};
      }
      &.--warning {
        --background-color: #{map-get($button--warning-background, active)};
        --border-color: #{map-get($button--warning-border, active)};
        --color: #{map-get($button--warning-color, active)};
      }
    }
  }
}

/**
 * Button, disabled.
 */

.button {
  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(10%);
    opacity: 0.5;
  }
}

/**
 * Button, focused.
 */

.button {
  &:focus {
    @extend .button:hover;
  }
}

/**
 * Button, hovered.
 */

.button {
  &:hover {
    &:not(:disabled):not(.is-disabled) {
      --background-color: #{map-get($button-background, hover)};
      --border-color: #{map-get($button-border, hover)};
      --color: #{map-get($button-color, hover)};

      &.--primary {
        --background-color: #{map-get($button--primary-background, hover)};
        --border-color: #{map-get($button--primary-border, hover)};
        --color: #{map-get($button--primary-color, hover)};
      }
      &.--danger {
        --background-color: #{map-get($button--danger-background, hover)};
        --border-color: #{map-get($button--danger-border, hover)};
        --color: #{map-get($button--danger-color, hover)};
      }
      &.--success {
        --background-color: #{map-get($button--success-background, hover)};
        --border-color: #{map-get($button--success-border, hover)};
        --color: #{map-get($button--success-color, hover)};
      }
      &.--warning {
        --background-color: #{map-get($button--warning-background, hover)};
        --border-color: #{map-get($button--warning-border, hover)};
        --color: #{map-get($button--warning-color, hover)};
      }
    }
  }
}
