/**
 * Component Icon settings.
 */

// =============================================================================
// Icon settings
// =============================================================================

// Set icon offset.
$icon-offset: 0.25em;

/**
 * Variants.
 */

// Set icon colors map.
$icon-color: (
  danger  : $red--sunset,
  info    : $blue--ribbon,
  success : $green--caribbean,
  warning : $yellow--selective,
);
