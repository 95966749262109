/**
 * Component Container.
 *
 * @requires {placeholder} %group
 */

// =============================================================================
// Container
// =============================================================================

%container {
  // @extend %group !optional;

  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}

.container {
  // @extend %container;
}

/**
 * Modifiers.
 */

/**
 * Container, breakout the parent.
 */

.container {
  &.--breakout {
    left: 50%;
    right: 50%;
    margin: {
      left: -50vw;
      right: -50vw;
    }
    width: 100vw;
  }
}

/**
 * Container, size variants.
 */

.container {
  @each $modifier in map-keys($container-size) {
    &.--#{$modifier} {
      margin: {
        left: auto;
        right: auto;
      }
      max-width: map-get($container-size, $modifier);
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
