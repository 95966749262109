/**
 * Component Hint.
 *
 * React Tooltip wrapper.
 * @see https://github.com/react-component/tooltip
 *
 * @requires {function}    px-to-rem
 */

// =============================================================================
// Hint
// =============================================================================

.hint {
  --arrow-bottom: auto;
  --arrow-left: auto;
  --arrow-right: auto;
  --arrow-top: auto;
  --arrow-dx: 0;
  --arrow-dy: 0;

  animation: hint-enter 0.3s 0.8s both;
  display: inline-block;
  font: {
    size: px-to-rem($hint-font-size);
    weight: $hint-font-weight;
  }
  padding: $hint-space;
  pointer-events: none;
  z-index: 1;
}

/**
 * Modifiers.
 */

/**
 * Hint, hidden.
 */

.hint {
  &[class*="hidden"] {
    display: none;
  }
}

/**
 * Hint, placement.
 */

.hint {
  &[class*="bottom"] {
    --arrow-top: 0;
    --arrow-dy: 10%;
  }
  &[class*="bottom"],
  &[class*="top"] {
    --arrow-left: 50%;
    --arrow-dx: -50%;
  }
  &[class*="top"] {
    --arrow-bottom: 0;
    --arrow-dy: -10%;
  }
  &[class*="left"] {
    --arrow-right: 0;
    --arrow-dx: -10%;
  }
  &[class*="left"],
  &[class*="right"] {
    --arrow-top: 50%;
    --arrow-dy: -50%;
  }
  &[class*="right"] {
    --arrow-left: 0;
    --arrow-dx: 10%;
  }
}

/**
 * Animations.
 */

@keyframes hint-enter {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Hint arrow
// =============================================================================

.hint-arrow {
  background-color: $hint-background;
  bottom: var(--arrow-bottom);
  display: inline-block;
  height: $hint-arrow-size;
  left: var(--arrow-left);
  position: absolute;
  right: var(--arrow-right);
  top: var(--arrow-top);
  transform:
    translate(var(--arrow-dx), var(--arrow-dy))
    rotate(45deg)
  ;
  width: $hint-arrow-size;
}

// Hint inner
// =============================================================================

.hint-inner {
  background: $hint-background;
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba($hint-background, 0.25);
  color: $hint-color;
  line-height: normal;
  padding: $hint-space / 2 * 3;
  position: relative;
}

// Hint label
// =============================================================================

.hint-label {
  & + .hint-key {
    margin-left: $hint-space;
  }
}

// Hint key
// =============================================================================

.hint-key {
  background-color: rgba($hint-color, 0.25);
  border-radius: 2px;
  color: $hint-color;
  display: inline-grid;
  font: {
    family: inherit;
    size: 1.1em;
  }
  min-width: 1.75em;
  padding:
    0.5 * $hint-space
    $hint-space
  ;
  place-items: center;
  text-transform: capitalize;

  & + & {
    margin-left: $hint-space;
  }
}
