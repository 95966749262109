/**
 * Component Animation settings.
 */

// =============================================================================
// Animation settings
// =============================================================================

/**
 * @link https://matthewlein.com/tools/ceaser
 */

$animation-easing: (
  in-circ:      cubic-bezier(0.600, 0.040, 0.980, 0.335),
  in-out:       cubic-bezier(0.420, 0.000, 0.580, 1.000),
  in-out-circ:  cubic-bezier(0.785, 0.135, 0.150, 0.860),
  in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000),
  out:          cubic-bezier(0.000, 0.000, 0.580, 1.000),
  out-cubic:    cubic-bezier(0.215, 0.610, 0.355, 1.000),
  out-expo:     cubic-bezier(0.190, 1.000, 0.220, 1.000),
  out-sine:     cubic-bezier(0.390, 0.575, 0.565, 1.000),
);
