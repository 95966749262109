/**
 * Component Label.
 */

// =============================================================================
// Label
// =============================================================================

.label {

  --background: #{$label-background};
  --border: #{$label-background};
  --color: WHITE;

  background-color: var(--background);
  border: 1px solid var(--border);
  border-radius: 2px;
  color: var(--color);
  display: inline-flex;
  font-size: 0.9rem;
  padding:
    0.25 * $label-indent
    0.75 *$label-indent;
  ;
  text-transform: uppercase;
  vertical-align: middle;

  & + & {
    margin-left: 1em;
  }
}

/**
 * Modifiers.
 */

/**
 * Label, primary.
 */

.label {
  &.--primary {
    --background: transparent;
    --color: #{$label-background};
  }
}

/**
 * Label, color variants.
 */

.label {
  @each $key, $color in $label-color {
    &.--#{$key} {
      --background: #{$color};
      --border: #{$color};

      &.--primary {
        --background: transparent;
        --color: #{$color};
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
