/**
 * Component Form / Select.
 *
 * @requires {placeholder} %form-element
 * @requires {variable}    $element-size
 * @requires element
 * @requires form.element
 */

// =============================================================================
// Form select
// =============================================================================

%form-select {
  @extend %form-element.--transparent !optional;

  appearance: none;
  border: 0;
  height: auto;

  [class] & {
    padding-right:
      $form-select-control-size
      + $form-select-indent
      + $form-select-control-indent
    ;
  }
}

.form-select {
  @extend %form-element !optional;

  position: relative;

  &[class] {
    padding: 0;
  }
  select {
    @extend %form-select;
  }
}

/**
 * Modifiers.
 */

/**
 * Form select, size.
 */

.form-select {
  @each $modifier in map-keys($element-size) {
    &.--#{$modifier} {
      %form-select {
        @extend %element--#{$modifier};
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Form select control
// =============================================================================

.form-select__control {
  pointer-events: none;
  position: absolute;
  right: $form-select-indent;

  &[class] {
    @include size($form-select-control-size, auto);
  }
}
