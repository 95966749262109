/**
 * Layer Color settings.
 */

// =============================================================================
// Color settings
// =============================================================================

// Need help with color names?
// @link http://veli.ee/colorpedia/
// @link http://www.color-blindness.com/color-name-hue/

$blue--dodger     : #3370ff;
$blue--fiord      : #3a4365;
$blue--haiti      : #0f152e;
$blue--manatee    : #878a98;
$blue--ribbon     : #004bff;
$blue--shark      : #222327;

$gray--alabaster  : #f8f8f8;
$gray--alto       : #d0d0d0;
$gray--comet      : #575b6d;
$gray--french     : #babcc8;
$gray--gallery    : #eee;
$gray--manatee    : #878a98;

/**
 * Accent colors.
 */

$green--caribbean  : #00ce9a;
$green--shamrock   : #27d388;
$red--sunset       : #fc4444;
$yellow--selective : #ffb200;

/**
 * Brand colors.
 */

/**
 * Neutral colors.
 */

// Color palette
// =============================================================================
