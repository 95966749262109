/**
 * Component Button / Group.
 */

// =============================================================================
// Button group
// =============================================================================

.button-group {

  --button-direction: 0;
  --button-group-gap: #{0.5 * $button-group-gap};

  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: calc((-1) * var(--button-group-gap));

  .button {
    @extend %button-group__item;
  }
}

/**
 * Modifiers.
 */

/**
 * Button group, block.
 */

.button-group {
  &.--block {
    display: flex;

    &.--end {
      justify-content: flex-end;
    }
    &.--start {
      justify-content: flex-start;
    }
  }
}

/**
 * Button group, compact.
 */

.button-group {
  &.--compact {
    --button-group-gap: #{0.25 * $button-group-gap};
  }
}

/**
 * Button group, stacked right.
 */

.button-group {
  &.--end   { --button-direction: 1; }
}

/**
 * Button group, stacked left.
 */

.button-group {
  &.--start { --button-direction: -1; }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Button group item
// =============================================================================

%button-group__item {
  margin: {
    bottom: var(--button-group-gap);
    left: calc(
      (var(--button-direction) + 1)
      * var(--button-group-gap)
    );
    right: calc(
      (-1 * var(--button-direction) + 1)
      * var(--button-group-gap)
    );
  }
}

.button-group__item {
  @extend %button-group__item;
}
