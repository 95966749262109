/**
 * Layer Utility.
 *
 * @requires {mixin}       text-truncate
 * @requires {mixin}       word-wrap
 */

// =============================================================================
// Utility
// =============================================================================

// =============================================================================
// Layout
// =============================================================================

/**
 * Display types.
 */

[class] {
  &.u-block        { display: block; }
  &.u-contents     { display: contents; }
  &.u-flex         { display: flex; }
  &.u-inline       { display: inline; }
  &.u-inline-block { display: inline-block; }
  &.u-inline-flex  { display: inline-flex; }
  &.u-none         { display: none; }
}

/**
 * Flexbox.
 */

[class*="flex"] {
  &.--wrap {
    flex-wrap: wrap;
  }
  &.--nowrap {
    flex-wrap: nowrap;
  }
}

.u-align {
  &.--base {
    align-items: baseline;
  }
  &.--middle {
    align-items: center;
  }
  &.--bottom {
    align-items: flex-end;
  }
  &.--top {
    align-items: flex-start;
  }
  &.--stretch {
    align-items: stretch;
  }
}

.u-direction {
  &.--column {
    flex-direction: column;

    &.--reverse {
      flex-direction: column-reverse;
    }
  }
  &.--row {
    flex-direction: row;

    &.--reverse {
      flex-direction: row-reverse;
    }
  }
}

.u-justify {
  &.--around {
    justify-content: space-around;
  }
  &.--between {
    justify-content: space-between;
  }
  &.--center {
    justify-content: center;
  }
  &.--end {
    justify-content: flex-end;
  }
  &.--start {
    justify-content: flex-start;
  }
}

[data-flex="01a"] { flex: 0 1 auto; }
[data-flex="110"] { flex: 1; }
[data-flex="1xx"] { flex-grow: 1; }
[data-flex="0xx"] { flex-grow: 0; }
[data-flex="x1x"] { flex-shrink: 1; }
[data-flex="x0x"] { flex-shrink: 1; }

/**
 * Floats and clear(s).
 */

.u-float {
  &.--end {
    float: right;
  }
  &.--none {
    float: none;
  }
  &.--start {
    float: left;
  }
}

.u-clear {
  &.--both {
    clear: both;
  }
  &.--end {
    clear: right;
  }
  &.--start {
    clear: left;
  }
}

.u-group {
  @extend %group;
}

/**
 * Position.
 */

.u-off {
  left: -1000vw;
  position: absolute;
  top: -1000vh;
}

// =============================================================================
// Typography
// =============================================================================

.u-font {
  &.--bold {
    font-weight: 600;
  }
}

.u-rhythm {
  &[class][class] {
    margin-bottom: rhythm-get();
  }
}

.u-text {
  // Horizontal alignment
  &.--center  { text-align: center; }
  &.--left    { text-align: left; }
  &.--right   { text-align: right; }

  // Vertical alignment
  &.--baseline { vertical-align: baseline; }
  &.--bottom   { vertical-align: bottom; }
  &.--middle   { vertical-align: middle; }
  &.--top      { vertical-align: top; }

  // Decoration
  &.--none      { text-decoration: none; }
  &.--strike    { text-decoration: line-through; }
  &.--underline { text-decoration: underline; }

  // Transformation
  &.--capital { text-transform: capitalize; }
  &.--lower   { text-transform: lowercase; }
  &.--none    { text-transform: none; }
  &.--upper   { text-transform: uppercase; }

  &.--compact { line-height: 1.4; }
  &.--loose   { line-height: 1.8; }
}

.u-transform {
  &.--capital { text-transform: capitalize; }
  &.--lower   { text-transform: lowercase; }
  &.--none    { text-transform: none; }
  &.--upper   { text-transform: uppercase; }
}

.u-truncate {
  @include text-truncate;
}

.u-whitespace {
  &.--nowrap {
    white-space: nowrap;
  }
}

.u-word {
  &.--wrap {
    @include word-wrap;
  }
}

/**
 * Line clamping.
 *  @link https://css-tricks.com/line-clampin/#article-header-id-0
 */

[data-clamp] {
  /* autoprefixer: off */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

@each $n in (1, 3) {
  [data-clamp="#{$n}"] {
    -webkit-line-clamp: $n;
  }
}
