/**
 * Component Button settings.
 */

// =============================================================================
// Button settings
// =============================================================================

// Set button background map.
$button-background: (
  active:     $blue--ribbon, // pressed
  disable:    WHITE,         // disabled
  focus:      $blue--ribbon, // focused
  hover:      $blue--ribbon, // hovered
  initial:    WHITE,
);

// Set button border map.
$button-border: (
  active:     $blue--ribbon, // pressed
  disable:    $blue--ribbon, // disabled
  focus:      $blue--ribbon, // focused
  hover:      $blue--ribbon, // hovered
  initial:    $blue--ribbon,
);

// Set button color map.
$button-color: (
  active:     WHITE,         // pressed
  disable:    $blue--ribbon, // disabled
  focus:      WHITE,         // focused
  hover:      WHITE,         // hovered
  initial:    $blue--ribbon,
);

// Set button padding.
$button-indent: 1em;

// Set button variants map.
// $button-variants: (
//   primary,
//   danger,
//   success,
//   warning,
// );

/**
 * Primary button.
 */

// Set button background map.
$button--primary-background: (
  active:     WHITE,         // pressed
  disable:    $blue--ribbon, // disabled
  focus:      WHITE,         // focused
  hover:      WHITE,         // hovered
  initial:    $blue--ribbon,
);

// Set button border map.
$button--primary-border: (
  active:     $blue--ribbon, // pressed
  disable:    $blue--ribbon, // disabled
  focus:      $blue--ribbon, // focused
  hover:      $blue--ribbon, // hovered
  initial:    $blue--ribbon,
);

// Set button color map.
$button--primary-color: (
  active:     $blue--ribbon, // pressed
  disable:    WHITE,         // disabled
  focus:      $blue--ribbon, // focused
  hover:      $blue--ribbon, // hovered
  initial:    WHITE,
);

/**
 * Secondary button.
 */

// Set button background map.
$button--secondary-background: (
  active:     inherit,     // pressed
  disable:    inherit,     // disabled
  focus:      inherit,     // focused
  hover:      inherit,     // hovered
  initial:    transparent,
);

// Set button border map.
$button--secondary-border: ();

// Set button color map.
$button--secondary-color: ();

/**
 * Danger button.
 */

// Set button background map.
$button--danger-background: (
  active:     $red--sunset, // pressed
  disable:    WHITE,        // disabled
  focus:      $red--sunset, // focused
  hover:      $red--sunset, // hovered
  initial:    WHITE,
);

// Set button border map.
$button--danger-border: (
  active:     $red--sunset, // pressed
  disable:    $red--sunset, // disabled
  focus:      $red--sunset, // focused
  hover:      $red--sunset, // hovered
  initial:    $red--sunset,
);

// Set button color map.
$button--danger-color: (
  active:     WHITE,        // pressed
  disable:    $red--sunset, // disabled
  focus:      WHITE,        // focused
  hover:      WHITE,        // hovered
  initial:    $red--sunset,
);

/**
 * Success button.
 */

// Set button background map.
$button--success-background: (
  active:     $green--caribbean, // pressed
  disable:    WHITE,             // disabled
  focus:      $green--caribbean, // focused
  hover:      $green--caribbean, // hovered
  initial:    WHITE,
);

// Set button border map.
$button--success-border: (
  active:     $green--caribbean, // pressed
  disable:    $green--caribbean, // disabled
  focus:      $green--caribbean, // focused
  hover:      $green--caribbean, // hovered
  initial:    $green--caribbean,
);

// Set button color map.
$button--success-color: (
  active:     WHITE,             // pressed
  disable:    $green--caribbean, // disabled
  focus:      WHITE,             // focused
  hover:      WHITE,             // hovered
  initial:    $green--caribbean,
);

/**
 * Warning button.
 */

// Set button background map.
$button--warning-background: (
  active:     $yellow--selective, // pressed
  disable:    WHITE,              // disabled
  focus:      $yellow--selective, // focused
  hover:      $yellow--selective, // hovered
  initial:    WHITE,
);

// Set button border map.
$button--warning-border: (
  active:     $yellow--selective, // pressed
  disable:    $yellow--selective, // disabled
  focus:      $yellow--selective, // focused
  hover:      $yellow--selective, // hovered
  initial:    $yellow--selective,
);

// Set button color map.
$button--warning-color: (
  active:     WHITE,              // pressed
  disable:    $yellow--selective, // disabled
  focus:      WHITE,              // focused
  hover:      WHITE,              // hovered
  initial:    $yellow--selective,
);
