/**
 * Component Container settings.
 */

// =============================================================================
// Container settings
// =============================================================================

// Set container size.
$container-size: (
  mean   : 1000px,
  narrow : 480px,
);
