/**
 * Component Avatar.
 *
 * @requires {mixin}       size
 */

// =============================================================================
// Avatar
// =============================================================================

.avatar {
  --background: #{$avatar-background};
  --color: #{$avatar-color};
  --size: #{$avatar-size};

  @include size(var(--size));

  background-color: var(--background);
  border-radius: 50%;
  color: var(--color);
  display: grid;
  flex-shrink: 0;
  font: {
    size: px-to-rem($avatar-font-size);
    weight: $avatar-font-weight;
  }
  place-items: center;
  user-select: none;
}
