/**
 * Component List.
 */

// =============================================================================
// List
// =============================================================================

.list {}

/**
 * Modifiers.
 */

/**
 * List reset.
 */

%list--reset {
  list-style: none;
  padding: 0;

  > li {
    padding-left: 0;
  }
}

/**
 * Compact list.
 */

.list {
  &.--compact {
    > li {
      margin-bottom: 0;
    }
  }
}

/**
 * List with stacked items.
 */

.list {
  &.--stack {
    @extend %list--reset;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
