/**
 * Component Tag settings.
 */

// =============================================================================
// Tag settings
// =============================================================================

// Set tag background.
$tag-background: SLATEGRAY;

// Set tag color.
$tag-color: WHITE;

// Set tag font size.
$tag-font-size: 11px;

// Set tag indent.
$tag-indent: (
  x: 10px,
  y: 4px,
);

// Set tag control size.
$tag-control-size: 16px;

// Set tag color theme.
$tag-theme: (
  danger  : $red--sunset,
  info    : $blue--ribbon,
  success : $green--shamrock,
  warning : $yellow--selective,
);
