/**
 * Component Backdrop settings.
 */

// =============================================================================
// Backdrop settings
// =============================================================================

// Set backdrop color.
$backdrop-color: BLACK;

// Set backdrop `z-index`.
$backdrop-index: 3;
