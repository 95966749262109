/**
 * Component Tag / Group.
 */

// =============================================================================
// Tag group
// =============================================================================

.tag-group {

  --tag-direction: 0;
  --tag-group-gap: #{0.5 * $tag-group-gap};

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: calc((-1) * var(--tag-group-gap));
  max-width: 100%;

  .tag {
    @extend %tag-group__item;
  }
}

/**
 * Modifiers.
 */

/**
 * Tag group, block.
 */

.tag-group {
  &.--end {
    justify-content: flex-end;
  }
  &.--start {
    justify-content: flex-start;
  }
}

/**
 * Tag group, stacked right.
 */

.tag-group {
  &.--end   { --tag-direction: 1; }
}

/**
 * Tag group, stacked left.
 */

.tag-group {
  &.--start { --tag-direction: -1; }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Tag group item
// =============================================================================

%tag-group__item {
  margin: {
    bottom: var(--tag-group-gap);
    left: calc(
      (var(--tag-direction) + 1)
      * var(--tag-group-gap)
    );
    right: calc(
      (-1 * var(--tag-direction) + 1)
      * var(--tag-group-gap)
    );
  }
}

.tag-group__item {
  @extend %tag-group__item;
}
