/**
 * Component Form / Textarea.
 *
 * @requires {mixin}       word-wrap
 * @requires {placeholder} %form-element
 * @requires form.element
 */

// =============================================================================
// Form textarea
// =============================================================================

.form-textarea {
  --size: #{$form-textarea-size};
  --space: #{map-get($form-textarea-control-offset, x)};

  position: relative;

  textarea {
    height: auto;
    line-height: inherit;
    padding: {
      bottom: map-get($form-textarea-indent, y);
      top: map-get($form-textarea-indent, y);
    }
  }
}

/**
 * Modifiers.
 */

/**
 * Textarea, autogrowing.
 * 1. Prevent jumpy behaviour.
 * 2. Hide scrollbar.
 */

.form-textarea {
  &.--growing {
    display: grid;
    font-weight: 500;

    &::after {
      @extend textarea !optional;

      content: attr(data-textarea) " "; /* 1 */
      visibility: hidden;

      @if map-get($form-textarea-features, debug) {
        background-color: rgba(LIME, 0.5);
        pointer-events: none;
        visibility: visible;
      }
    }

    &::after,
    textarea {
      @include word-wrap;

      display: block;
      font-weight: inherit;
      grid: {
        column: 1 / -1;
        row: 1 / -1;
      }
      max-height: var(--size);
      padding-right: var(--space);
    }

    textarea {
      // overflow: hidden; /* 2 */
      resize: none;

      @if map-get($form-textarea-features, debug) {
        background-color: GOLD;
      }
    }
  }
}

/**
 * States.
 */

// .is-component-state {}

// .component {
//   &.is-state {}
// }

/**
 * Context.
 */

/**
 * Textarea, with control.
 */

.form-textarea {
  &.has-control {
    --space: calc(
      #{$form-textarea-control-size}
      + #{map-get($form-textarea-control-offset, x)}
    );
  }
}


/**
 * Animations.
 */

// @keyframes component-animation {}

// =============================================================================
// Subcomponents
// =============================================================================

// Textarea control
// =============================================================================

.form-textarea__control {
  @include size($form-textarea-control-size);

  padding: 0;
  position: absolute;
  right: map-get($form-textarea-control-offset, x);
  top: map-get($form-textarea-control-offset, y);

  svg {
    @include size(16px);
  }
}
