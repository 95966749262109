/**
 * Component Form / Textarea settings.
 *
 * @requires {function}    rhythm-get
 */

@import "helpers/functions";

// =============================================================================
// Textarea settings
// =============================================================================

// Set textarea features.
$form-textarea-features: (
  debug: false,
);

// Set textarea indent.
$form-textarea-indent: (
  x: $form-element-indent,
  y: rhythm-get(),
);

// Set textarea max. height.
$form-textarea-size: 30vmin;

/**
 * Control.
 */

// Set textarea control size.
$form-textarea-control-size: 24px;

// Set textarea control offset.
$form-textarea-control-offset: (
  x: 8px,
  y: 8px,
);
