/**
 * Provides Micro clearfix.
 *
 * @link http://nicolasgallagher.com/micro-clearfix-hack/
 *
 * Known support: Firefox 3.5+, Safari 4+, Chrome, Opera 9+, IE 6+
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *  contenteditable attribute is included anywhere else in the document.
 *  Otherwise it causes space to appear at the top and bottom of elements
 *  that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *  `:before` to contain the top-margins of child elements.
 */

@mixin microfix {
  &::after,
  &::before {
    content: "";    /* 1 */
    display: table; /* 2 */
  }
  &::after {
    clear: both;
  }
}
