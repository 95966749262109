/**
 * View Session.
 *
 * @requires {mixin}    stretch
 */

@import "../platform";

// =============================================================================
// Session view
// =============================================================================

[data-view="session"] {
  &[class] {
    max-width: 580px;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Card
// =============================================================================

[data-session="card"] {
  &[class] {
    margin-top: 15vh;
    padding: 3rem;
  }

  &::before {
    box-shadow: 0 10px 50px rgba(BLACK, 0.125);
  }
}

// Table
// =============================================================================

[data-session="table"] {
  --path-color: #a3a3a3;

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        --path-color: WHITE;
      }
    }

    td {
      $indent: 36px;

      &:first-child {
        &::before {
          left: (-1) * $indent;
        }
        .label {
          line-height: 1;
        }
      }

      &:last-child {
        &::before {
          right: (-1) * $indent;
        }
      }
    }
  }

  .label {
    font-size: 0.7em;
  }

  path {
    fill: var(--path-color);
    transition: fill 0.3s;
  }
}

// Stamp
// =============================================================================

[data-session="stamp"] {
  align-items: center;
  background-color: WHITE;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  text: {
    align: center;
    transform: uppercase;
  }
  width: 90px;

  svg {
    opacity: 0;
    position: absolute;
    transform: scale(0.5);
    transition: {
      duration: 0.2s;
      property:
        opacity,
        transform,
      ;
    }
    z-index: 1;
  }

  &::after {
    @include stretch;

    background-color: inherit;
    content: "";
    opacity: 0;
    transition: opacity 0.2s;
  }
}

/**
 * States.
 */

/**
 * Stamp, hovered.
 */

[data-session="stamp"] {
  &:hover {
    svg {
      opacity: 1;
      transform: scale(1);
    }
    &::after {
      opacity: 1;
    }
  }
}
