// TODO: Refactor and move to visitor

.not-authenticated-view {
  header {
    background: #e1e1e1;
    height: 30vh;
    padding: 50px 100px;

    h1 {
      font-weight: 700;
      font-size: 20px;
    }
  }

  .container {
    margin-top: -10vh;
    z-index: 2;

    .card {
      p {
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3vh;
        font-weight: 700;
        margin: 0;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 4vh;
        min-width: 200px;
      }

      button {
        margin-top: 1vh;
        margin-bottom: 4vh;
      }

      .code-input {
        width: 200px;
        height: 90px;
        overflow: hidden;
        display: flex !important;
        align-items: flex-start;
        justify-content: center;
        animation: expand 1s ease-in-out;

        input {
          margin-top: 10px !important;
          margin-bottom: 10px !important;
        }
      }

      @keyframes expand {
        0% {
          height: 0;
          opacity: 0;
        }
        50% {
          height: 90px;
          opacity: 0;
        }
      }
    }
  }
}
