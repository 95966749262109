/**
 * Component Grid.
 */

// =============================================================================
// Grid
// =============================================================================

/**
 * Breakpoint-free badass (•̀ᴗ•́)و
 */

[data-grid] {
  @include grid;

  &:not([data-grid="ignore"]) {
    display: grid;
  }

  @if map-get($grid-features, debug) {
    & > * {
      background-color: CRIMSON;

      &:nth-child(even) {
        background-color: GOLD;
      }
    }
  }
}

/**
 * Modifiers.
 */

/**
 * Grid, ignore columns.
 */

[data-grid="ignore"] {
  grid-column: 1 / -1;
}
