/**
 * Component Element settings.
 */

// =============================================================================
// Element settings
// =============================================================================

// Set element border size.
$element-border-size: 1px;

// Set element font size.
$element-font-size: (
  large:  13px,
  medium: 13px,
  small:  11px,
);

// Set element indent.
$element-indent: (
  large:  2em,
  medium: 1.125em,
  small:  1em,
);

// Set element width.
$element-length: (
  long:  40em,
  mean:  20em,
  short: 4em,
);

// Set element height.
$element-size: (
  large:  46px,
  medium: 34px,
  small:  26px,
);
