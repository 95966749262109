/**
 * Component Badge settings.
 */

// =============================================================================
// Badge settings
// =============================================================================

// Set badge background.
$badge-background: transparent;

// Set badge border radius.
$badge-border-radius: 1000px;

// Set badge color.
$badge-color: inherit;

// Set badge font size.
$badge-font-size: 9px;

// Set badge font weight.
$badge-font-weight: 700;

// Set badge indent.
$badge-indent: 0.125em;

// Set badge letter spacing.
$badge-letter-spacing: -50;

// Set badge colors map.
$badge-color: (
  danger  : $red--sunset,
  info    : $blue--ribbon,
  success : $green--caribbean,
  warning : $yellow--selective,
);
