/**
 * View Visitor.
 *
 * @requires {mixin}    size
 * @requires {mixin}    stretch
 */

@import "../platform";

// TODO: Refactor presenter

// =============================================================================
// Visitor view
// =============================================================================

// Body
// =============================================================================

/**
 * Presenter is sharing screen.
 * TODO: Refactor
 */

[data-view="presenter"] {
  .app__body {
    [data-video="container"] {
      @include stretch;

      video {
        height: 100%;
        object-fit: contain !important;
      }
    }
  }
}

/**
 * Context.
 */

/**
 * App, with sidebar.
 */

[data-view="presenter"] {
  &.has-control,
  &.has-people {
    [data-video="dock"] {
      &.is-active {
        transform: translateX(-300px);
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Video
// =============================================================================

[data-video="dock"] {}

/**
 * States.
 */

/**
 * Dock, active.
 */

[data-video="dock"] {
  &.is-active {
    @include size(214px, 120px);

    background-color: rgba(gold, 0.5);

    $indent: 16px;
    $offset: 5px;

    bottom: unset;
    left: unset;
    position: fixed;
    right: $indent + $offset;
    top: calc(60px + #{$indent + $offset});
    transition: transform 0.5s map-get($animation-easing, in-out-cubic) 0.1s;
    will-change: transform;
    z-index: 1;

    &::before {
      @include stretch((-1) * $offset);

      background: rgba($blue--haiti, 0.8);
      border-radius: 2px;
      content: "";
    }

    [data-video="controls"] {
      @include stretch(absolute, 4px);
    }
  }
}

// AV Controls
// =============================================================================

/**
 * TODO: Refactor
 */

[data-visitor="controls"] {
  bottom: 0;
  display: grid;
  gap: 0 16px;
  grid-template-columns: repeat(2, 1fr);
  left: 50%;
  padding-bottom: 24px;
  position: fixed;
  transform: translateX(-50%);
  z-index: 1;

  .group {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    position: relative;
    width: 92px;

    &::before {
      @include stretch;

      background-color: $gray--comet;
      border-radius: 1000px;
      content: "";
      transition: background-color 0.3s;
    }

    &:nth-child(1) {
      [data-control] {
        pointer-events: none;
      }

      &:hover {
        &::before {
          background-color: transparent;
        }

        [data-control] {
          pointer-events: all;
        }
      }
    }
  }
}

// Control, volume
// =============================================================================

/**
 * TODO: Move to components and refactor
 */

[data-control="volume"] {

  $slider-length: 130px;
  $slider-indent: 8px;
  $slider-rail-size: 4px;
  $slider-handle-size: 30px;
  $slider-handle-icon-size: 16px;

  background: $gray--comet;
  bottom: calc(50%);
  display: flex;
  height: $slider-length;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  width: $slider-handle-size;
  z-index: 1;

  &::before {
    @include stretch(
      (-(0.5 * $slider-handle-size + 8px)),
      (-1) * $slider-indent
    );
    background: inherit;
    border-radius: 1000px;
    content: "";
  }

  [class*="slider"] {
    padding: 0;
    width: $slider-rail-size;
  }

  [class*="track"][class] {
    --color: #{$green--shamrock};

    left: auto;
    width: inherit;
  }

  [class*="handle"] {
    @include size($slider-handle-size);

    --color: #{$blue--ribbon};

    background: var(--color);
    border: 0;
    color: WHITE;
    display: grid;
    margin: 0 !important;
    place-items: center;
    transform: translate(calc(-50% + 0.5 * #{$slider-rail-size}), 50%) !important;
    transition: background-color 0.2s;

    svg {
      @include size($slider-handle-icon-size);
    }

    &[aria-valuenow="0"] {
      --color: #{$red--sunset};
    }
  }

  [class*="mark"] {
    display: none;
  }

  .control {
    --color: #{$blue--fiord};
  }

}

/**
 * States.
 */

/**
 * Control, hovered.
 */

[data-control="volume"] {
  &:hover {
    opacity: 1;
  }
}
