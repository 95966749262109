/**
 * Component App.
 *
 * @requires {mixin} stretch
 */

// =============================================================================
// App
// =============================================================================

.app {

  --app-indent-x: #{map-get($app-indent, x)};
  --app-indent-y: #{map-get($app-indent, y)};

  display: grid;
  grid-template: {
    areas:
      "head"
      "body"
      "foot"
    ;
    rows:
      minmax(
        #{map-get($app-head-size, min)},
        #{map-get($app-head-size, max)}
      )
      auto
      min-content
    ;
  }
  height: 100vh;
  margin: 0 auto;
  min-width: map-get($app-size, min);
  overflow-x: hidden;

  @if map-get($app-features, debug) {
    background-color: GOLD;
  }
}

/**
 * States.
 */

/**
 * App, disabled scrolling.
 */

.app {
  &[data-scroll="disable"] {
    max-height: 100vh;
    overflow: hidden;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// App inset
// =============================================================================

%app__inset {
  padding:
    var(--app-indent-y)
    var(--app-indent-x)
  ;
}

.app__inset {
  margin: auto;
  position: relative;
  width: #{"min(100%, #{map-get($app-size, max)})"};

  @if map-get($app-features, debug) {
    background-color: rgba(MEDIUMSLATEBLUE, 0.75);
  }
}

/**
 * Modifiers.
 */

/**
 * App inset, flex.
 */

.app__inset {
  &.--flex {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

// App head
// =============================================================================

.app__head {
  @extend %app__inset;

  --app-indent-y: #{0.5 * map-get($app-indent, y)};

  align-items: center;
  background-color: $app-background;
  border-bottom: 1px solid $app-border;
  display: flex;
  flex-flow: column;
  grid-area: head;

  @if map-get($app-features, debug) {
    background-color: rgba(TOMATO, 0.85);
  }
}

// App body
// =============================================================================

.app__body {
  @extend %app__inset;

  grid-area: body;
  overflow-y: auto;
  position: relative;

  @if map-get($app-features, debug) {
    background-color: AQUAMARINE;
  }
}

// App title
// =============================================================================

.app__title {
  font-size: map-get($base-scale, 5) * 1rem;
  margin: 0;
}

// App sidebar
// =============================================================================

.app__sidebar {
  --sidebar-border-left: 0;
  --sidebar-border-right: 0;
  --sidebar-direction: 0;

  background-color: $app-sidebar-background;
  border-top: 1px solid $app-border;
  min-height: 0;
  opacity: 0;
  transform: translateX(calc(var(--sidebar-direction) * 150%));
  transition: {
    duration: 0.3s;
    property:
      opacity,
      transform,
    ;
    timing-function: map-get($animation-easing, in-out-cubic);
  }
  will-change:
    opacity,
    transform
  ;

  /**
   * Faux sidebar border.
   */

  &::after {
    background: rgba($app-border, 0.5);
    bottom: 0;
    content: "";
    left: var(--sidebar-border-left);
    position: absolute;
    right: var(--sidebar-border-right);
    top: -1px;
    width: 1px;
  }
}

/**
 * Modifiers.
 */

/**
 * App sidebar, left.
 */

.app__sidebar {
  &.--start {
    --sidebar-border-left: auto;
    --sidebar-border-right: -1px;
    --sidebar-direction: -1;
  }
}

/**
 * App sidebar, right.
 */

.app__sidebar {
  &.--end {
    --sidebar-border-left: -1px;
    --sidebar-border-right: auto;
    --sidebar-direction: 1;
  }
}

/**
 * States.
 */

/**
 * App sidebar, active.
 */

.app__sidebar {
  &.is-active {
    --sidebar-direction: 0;

    opacity: 1;
  }
}

// App foot
// =============================================================================

.app__foot {
  @extend %app__inset;

  background-color: $app-background;
  grid-area: foot;

  @if map-get($app-features, debug) {
    background-color: rgba(SALMON, 0.85);
  }
}
