/**
 * Component Animation / Fade.
 */

// =============================================================================
// Fade animation
// =============================================================================

// .component {}

/**
 * Animations.
 */

/**
 * Fade in.
 */

@keyframes fade--in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
 * Fade out.
 */

@keyframes fade--out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
