/**
 * Component Counter.
 */

// =============================================================================
// Counter
// =============================================================================

.counter {

  --counter-prefix: #{$counter-prefix};
  --counter-suffix: #{$counter-suffix};

  counter-reset: counter;
}

// =============================================================================
// Subcomponents
// =============================================================================

// Counter item
// =============================================================================

.counter__item {
  &::before {
    content: var(--counter-prefix) counter(counter) var(--counter-suffix);
  }
}
