/**
 * Component Indicator / Progress settings.
 */

// =============================================================================
// Progress indicator settings
// =============================================================================

// Set indicator border radius.
$indicator-progress-border-radius: 2px;

// Set indicator color.
$indicator-progress-color: $blue--ribbon;

// Set indicator indent.
$indicator-progress-indent: 1rem;

// Set indicator index.
$indicator-progress-index: 1;

/**
 * Progress indicator, body.
 */

// Set indicator body font color.
$indicator-progress-body-color: WHITE;
