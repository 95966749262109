/**
 * Component List / User settings.
 */

// =============================================================================
// User list settings
// =============================================================================

// Set border color.
$list-user-border-color: $gray--gallery;

// Set font size.
$list-user-font-size: 12px;

/**
 * Item.
 */

// Set item background color map.
$list-user-item-background: (
  active:     initial,        // pressed
  disable:    $gray--gallery, // disabled
  focus:      initial,        // focused
  hover:      initial,        // hovered
  initial:    #838798,
);

// Set item color map.
$list-user-item-color: (
  active:     inherit,     // pressed
  disable:    $gray--alto, // disabled
  focus:      inherit,     // focused
  hover:      inherit,     // hovered
  initial:    inherit,
);

// Set item preview size.
$list-user-item-preview-size: (
  height: 50px,
  width: 90px,
);
