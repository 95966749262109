/**
 * Component Hint settings.
 */

// =============================================================================
// Hint settings
// =============================================================================

// Set hint background.
$hint-background: BLACK;

// Set hint color.
$hint-color: WHITE;

// Set hint font size.
$hint-font-size: 10px;

// Set hint font weight.
$hint-font-weight: 500;

// Set hint space.
$hint-space: 4px;

// Set hint arrow size.
$hint-arrow-size: 16px;
