/**
 * Component Card.
 *
 * @requires {function}    rhythm-get
 * @requires {placeholder} %container
 * @requires container
 */

// =============================================================================
// Card
// =============================================================================

.card {
  @extend %container !optional;

  --card-background: #{$card-background};
  --card-border-color: #{$card-border-color};
  --card-border-radius: #{$card-border-radius};
  --card-indent: #{$card-indent};

  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  margin-bottom: rhythm-get(1);
  padding: var(--card-indent);
  position: relative;

  @if map-get($card-features, debug) {
    background-color: rgba(GOLD, 0.75);
  }
}

/**
 * Modifiers
 */

/**
 * Card, primary.
 */

.card {
  &.--primary {
    $ratio: 2;

    --card-border-radius: #{$ratio * $card-border-radius};
    --card-indent: #{$ratio * $card-indent};
  }
}

/**
 * Card, secondary.
 */

.card {
  &.--secondary {
    --card-background: transparent;
  }
}

/**
 * Card, tertiary.
 */

.card {
  &.--tertiary {
    --card-background: transparent;
    --card-border-color: transparent;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Card title
// =============================================================================

.card__title {
  font-size: $card-title-font-size;
  margin: {
    bottom: rhythm-get();
    top: 0;
  }

  @if map-get($card-features, debug) {
    background-color: rgba(TOMATO, 0.85);
  }
}
