/**
 * Component Form / Element settings.
 */

// =============================================================================
// Form element settings
// =============================================================================

// Set form element background.
$form-element-background: (
  disable:    transparent,                // disabled
  focus:      WHITE,                      // focused
  hover:      WHITE,                      // hovered
  initial:    $gray--alabaster,
  invalid:    lighten($red--sunset, 35%), // invalid
);

// Set form element border color.
$form-element-border-color: (
  disable:    lighten($blue--manatee, 20%), // disabled
  focus:      $blue--ribbon,                // focused
  hover:      $blue--ribbon,                // hovered
  initial:    lighten($blue--manatee, 20%),
  invalid:    $red--sunset,                 // invalid
);

// Set form element border radius.
$form-element-border-radius: 2px;

// Set form element border width.
$form-element-border-size: 1px;

// Set form element color.
$form-element-color: (
  disable:    lighten($base-color, 30%),  // disabled
  focus:      darken($base-color, 50%),   // focused
  hover:      darken($base-color, 50%),   // hovered
  initial:    $base-color,
  invalid:    $base-color,                // invalid
  require:    $red--sunset,               // required
);

// Set form element padding.
$form-element-indent: 1em;

// Set form element color.
$form-element-placeholder-color: (
  disable: $blue--manatee,            // disabled
  initial: lighten($base-color, 20%),
);
