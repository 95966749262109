/**
 * Component Backdrop.
 *
 * @requires {mixin} stretch
 * @requires animation.fade
 */

// =============================================================================
// Backdrop
// =============================================================================

%backdrop {
  background-color: rgba($backdrop-color, 0.75);
}

.backdrop {
  display: grid;
  place-items: center;
  z-index: $backdrop-index;

  &,
  &::before {
    @include stretch(fixed, 0);
  }

  &::before {
    @extend %backdrop;

    animation: fade--in 0.3s both;
    content: "";
    z-index: -1;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
