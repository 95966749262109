/**
 * Component Form.
 *
 * @requires {placeholder} %container
 * @requires container
 */

// =============================================================================
// Form
// =============================================================================

.form {

  --grid-gap-column: 1em;
  --grid-gap-row: 10px;
  --grid-column-min: 500px;

  @extend %container !optional;
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
