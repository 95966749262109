/**
 * Component List / User.
 *
 * @requires {function}    px-to-rem
 * @requires {mixin}       stretch
 * @requires {placeholder} %list--reset
 * @requires list
 */

// =============================================================================
// User list
// =============================================================================

.list-user {
  @extend %list--reset !optional;

  border-top: 1px solid $list-user-border-color;
  font-size: px-to-rem($list-user-font-size);
}

// =============================================================================
// Subcomponents
// =============================================================================

// List overlay
// =============================================================================

%list-user__overlay {
  @include stretch;
}

// List item
// =============================================================================

.list-user__item {
  --list-item-background: #{map-get($list-user-item-background, initial)};
  --list-item-color: #{map-get($list-user-item-color, initial)};

  align-items: center;
  color: var(--list-item-color);
  display: flex;
  margin: 0;
}

/**
 * States.
 */

/**
 * List item, disabled.
 */

.list-user__item {
  &.is-disabled {
    --list-item-background: #{map-get($list-user-item-background, disable)};
    --list-item-color: #{map-get($list-user-item-color, disable)};
  }
}

/**
 * List item, hovered.
 */

.list-user__item {
  &:hover {
    .list-user__item__controls {
      opacity: 1;
      pointer-events: all;
    }
    .list-user__item__labels {
      opacity: 0;
    }
  }
}

// List item title
// =============================================================================

.list-user__item__title {
  font-weight: 500;
  padding: {
    left: 16px;
    right: 16px;
  }
}

// List item preview
// =============================================================================

.list-user__item__preview {
  @include size(
    map-get($list-user-item-preview-size, width),
    map-get($list-user-item-preview-size, height)
  );

  background-color: var(--list-item-background);
  flex-shrink: 0;
  order: -1;
  position: relative;

  [id] {
    @extend %list-user__overlay;
  }
}

// List item avatar
// =============================================================================

.list-user__item__avatar {
  @extend %list-user__overlay;

  display: grid;
  place-items: center;
}

// List item labels
// =============================================================================

.list-user__item__labels {
  @extend %list-user__overlay;

  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  padding: 4px;
  transition: opacity 0.1s;
}

// List item controls
// =============================================================================

.list-user__item__controls {
  @extend %list-user__overlay;

  align-items: center;
  display: flex;
  justify-content: space-evenly;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}
