/**
 * Component Control.
 *
 * @requires {mixin} size
 * @requires animation.fade
 */

// =============================================================================
// Control
// =============================================================================

.control {
  --background-color: #{map-get($control-background, initial)};
  --border-color: #{map-get($control-border-color, initial)};
  --color: #{map-get($control-color, initial)};
  --element-size: 3em;
  --icon-scale: 1.5;
  --icon-size: 60%;
  --icon-dx: 0;
  --icon-dy: 0;

  animation: fade--in 0.3s both;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1;

  -webkit-tap-highlight-color: transparent;

  &:not(.is-disabled):not(.is-static) {
    cursor: pointer;
  }
}

/**
 * Modifiers.
 */

/**
 * Primary.
 * 1. Override FontAwesome props.
 */

.control {
  &.--primary {
    --background-color: #{map-get($control--primary-background, initial)};
    --border-color: #{map-get($control--primary-border, initial)};
    --color: #{map-get($control--primary-color, initial)};
  }
}

/**
 * Secondary.
 * 1. Override FontAwesome props.
 */

.control {
  &.--secondary {
    --fa-secondary-opacity: 0; /* 1 */
  }
}

/**
 * Large.
 */

.control {
  &.--large {
    --element-size: 4em;
    --icon-scale: 2.5;

    &.--secondary {
      --icon-scale: 3.5;
    }
  }
}

/**
 * Small.
 */

.control {
  &.--small {
    --element-size: 2em;
    --icon-scale: 1;
  }
}

/**
 * States.
 */

/**
 * Control, active.
 */

.control {
  &.is-active,
  &:active {
    &:not(:disabled):not(.is-disabled):not(.is-static) {
      --background-color: #{map-get($control-background, active)};
      --border-color: #{map-get($control-border-color, active)};
      --color: #{map-get($control-color, active)};

      &.--primary {
        --background-color: #{map-get($control--primary-background, active)};
        --border-color: #{map-get($control--primary-border, active)};
        --color: #{map-get($control--primary-color, active)};
      }
    }
  }
}

/**
 * Control, disabled.
 */

.control {
  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
    filter: grayscale(50%);
    opacity: 0.5;
  }
}

/**
 * Control, focused.
 */

.control {
  &:focus {
    @extend .control:hover;
  }
}

/**
 * Control, hovered.
 */

.control {
  &:hover {
    &:not(:disabled):not(.is-disabled):not(.is-static) {
      --background-color: #{map-get($control-background, hover)};
      --border-color: #{map-get($control-border-color, hover)};
      --color: #{map-get($control-color, hover)};

      &.--primary {
        --background-color: #{map-get($control--primary-background, hover)};
        --border-color: #{map-get($control--primary-border, hover)};
        --color: #{map-get($control--primary-color, hover)};
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Element
// =============================================================================

/**
 * 1. Override FontAwesome props.
 */

.control__element {
  @include size(var(--element-size));

  align-items: center;
  background-color: var(--background-color);
  border: $control-border-size solid var(--border-color);
  border-radius: 50%;
  color: var(--color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  transition: {
    duration: 0.2s;
    property:
      background-color,
      border-color,
      color,
      filter
      ;
  }

  svg {
    @include size(var(--icon-size)); /* 1 */

    display: inline-block;
    transform:
      scale(var(--icon-scale))
      translate(var(--icon-dx), var(--icon-dy))
    ;
  }
}

// Label
// =============================================================================

.control__label {
  color: map-get($control-background, initial);
  font-size: 0.7rem;
  margin-top: 0.5em;
  text-transform: uppercase;
}
