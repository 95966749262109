/**
 * Layer Base.
 *
 * @requires {function}    rhythm-get
 * @requires {placeholder} %rhythm
 * @requires animation.fade
 */

// =============================================================================
// Base
// =============================================================================

// @font-face {
//   font: {
//     display: optional;
//     family: "Inter";
//     src: url("../../fonts/inter-variable.woff2") format("woff2");
//     weight: 1 999;
//   }
// }

/**
 * 1. Set all (pseudo)elements `box-sizing` to `border-box`.
 * 2. Disable the default callout shown on iOS when you touch and hold a touch target.
 */

*,
*:after,
*:before {
  box-sizing: border-box; /* 1 */
  outline: none;

  -webkit-touch-callout: none; /* 2 */
}

html {
  font: {
    family: $base-font-stack;
    size: $base-font-size / $base-font-size--browser * 100%;
    weight: $base-font-weight;
  }
  line-height: $base-line-height-ratio;
  scroll-behavior: smooth;

  -webkit-font-smoothing: subpixel-antialiased;
}

html,
body {
  height: 100%;
}

body {
  background-color: $base-background;
  color: $base-color;
  margin: 0;
}

/**
 * Selection color.
 */

::selection {
  background-color: $base-selection-background;
}

// =============================================================================
// Typography
// =============================================================================

h1,
h2,
h3,
h4,
h5,
h6 {
  &:first-child {
    margin-top: 0;
  }
}

h1,
h2,
h3 {
  font-weight: 500;
  margin: {
    bottom: rhythm-get(1);
    top: rhythm-get(1);
  }
}

h4,
h5,
h6 {
  font-weight: 500;
  margin: {
    bottom: rhythm-get();
    top: rhythm-get(1);
  }
}

h6 {
  text-transform: uppercase;
}

@for $i from 1 through 6 {
  h#{$i} { font-size: map-get($base-scale, $i) * 1rem; }
}

p {
  @extend %rhythm;
}

// Inline elements
// =============================================================================

// a {}

b,
strong {
  font-weight: 500;
}

// =============================================================================
// Lists
// =============================================================================

ol,
ul {
  @extend %rhythm;

  padding-left: 2rem;
}

li {
  margin-bottom: rhythm-get(0.125);
}

li > ol,
li > ul {
  margin: {
    top: rhythm-get(0.125);
  }
}

// =============================================================================
// Embedded content
// =============================================================================

img,
video {
  max-width: 100%;
}

video {
  animation: fade--in 0.2s both;
}

// =============================================================================
// Group elements
// =============================================================================

code,
kbd,
pre,
samp {
  font: {
    family: monospace;
    size: 1.25em;
  }
}

// =============================================================================
// Form elements
// =============================================================================

label {}

input,
optgroup,
select,
textarea {
  font: {
    family: inherit;
    size: inherit;
  }
}

input,
select,
textarea {
  @extend %form-element !optional;

  color: inherit;
  outline: none;
}

/**
 * 1. Disable min. content size in favour of `flexbox`.
 *  @link https://bugzilla.mozilla.org/show_bug.cgi?id=1088586
 */

[type="email"],
[type="text"],
select {
  min-width: 0; /* 1 */
  text-overflow: ellipsis;
}

[type="number"] {
  text-align: right;
}

:disabled {
    cursor: not-allowed;
}

select {
  &[name] {
    padding-right: 0;
  }
}

/**
 * Textarea
 * 1. Allow only vertical resize.
 * 2. We need to increase specifity.
 */

textarea {
  resize: vertical;       /* 1 */

  &[name] {
    display: block;
  }
  &:not([rows="1"]) {     /* 2 */
    height: auto;
    line-height: inherit;
    padding: {
      bottom: map-get($form-textarea-indent, y);
      top: map-get($form-textarea-indent, y);
    }
  }
}

/**
 * Placeholder color.
 * @link http://css-tricks.com/snippets/css/style-placeholder-text/
 */

::placeholder {
  color: map-get($form-element-placeholder-color, initial);
}

:disabled::placeholder {
  color: map-get($form-element-placeholder-color, disable);
}

/**
 * Button
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  color: inherit;
  cursor: pointer;
  font: {
    family: inherit;
    weight: inherit;
  }
  width: auto;
}

// =============================================================================
// Tables
// =============================================================================

table {
  @extend %rhythm;

  border: {
    collapse: separate;
    spacing: 0;
  }
}

th {
  font-weight: 400;
  text-align: left;
}

td,
th {
  padding: 0.35rem 0.5rem;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

// =============================================================================
// Browser (OS) specific
// =============================================================================
