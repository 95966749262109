/**
 * Component Cursor settings.
 */

// =============================================================================
// Cursor settings
// =============================================================================

// Set cursor types list.
$cursor-types: (
  default,
  pointer,
);
