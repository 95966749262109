/**
 * Component Card settings.
 */

// =============================================================================
// Card settings
// =============================================================================

// Set card background.
$card-background: WHITE;

// Set card border color.
$card-border-color: $gray--gallery;

// Set card border radius.
$card-border-radius: 6px;

// Set card breakpoints.
$card-breaks: ();

// Set card features.
$card-features: (
  debug: false,
);

// set card indent.
$card-indent: 16px;

// set card title font size.
$card-title-font-size: map-get($base-scale, 5) * 1rem;
