/**
 * Component Element.
 */

// =============================================================================
// Element
// =============================================================================

%element {
  @extend %element--length;
  @extend %element--size;
}

/**
 * Modifiers.
 */

/**
 * Element length.
 */

%element--length {
  @each $modifier in map-keys($element-length) {
    &.--#{$modifier} {
      @extend %element--#{$modifier};
    }

    @at-root %element--#{$modifier} {
      max-width: map-get($element-length, $modifier);
    }
  }
}

/**
 * Element, size.
 */

%element--size {
  @each $modifier in map-keys($element-size) {
    &.--#{$modifier} {
      @extend %element--#{$modifier};
    }

    @at-root %element--#{$modifier} {
      $size: map-get($element-size, $modifier);

      font: {
        size: map-get($element-font-size, $modifier);
      }
      height: $size;
      line-height: $size - 2 * $element-border-size;
      padding: {
        left: map-get($element-indent, $modifier);
        right: map-get($element-indent, $modifier);
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
