/**
 * Component Message.
 *
 * @requires {function} px-to-rem
 *
 * @requires {function}    px-to-rem
 * @requires animation.fade
 */

// =============================================================================
// Message
// =============================================================================

.message {
  --background: #{$message-background};
  --border-color: #{$message-border-color};
  --icon-color: currentColor;

  animation: fade--in 0.6s 0.3s both;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  clear: both;
  margin-bottom: rhythm-get();
  padding: 1rem;

  svg {
    color: var(--border-color);
    margin-right: 0.75rem;
  }

  &:not(.--primary) {
    background-color: var(--background);
  }
}

/**
 * Modifiers.
 */

/**
 * Message, primary;
 */

.message {
  &.--primary {}
}

/**
 * Message, color variants.
 */

.message {
  @each $key, $c in $message-color {
    &.--#{$key} {
      $color: rgba($c, 0.5);

      --background: #{$color};
      --border-color: #{$color};

      &.--primary {
        --icon-color: #{$color};
      }
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Message title
// =============================================================================

.message__title {
  font-size: px-to-rem($message-title-font-size);
  margin: 0;
  text-transform: uppercase;

  & + p {
    margin-top: 0.25em;
  }
}
