/**
 * Component Avatar settings.
 */

// =============================================================================
// Avatar settings
// =============================================================================

// Set avatar background color.
$avatar-background: MEDIUMSLATEBLUE;

// Set avatar color.
$avatar-color: WHITE;

// Set avatar font size.
$avatar-font-size: 13px;

// Set avatar font weight.
$avatar-font-weight: 700;

// Set avatar size.
$avatar-size: 30px;
