// TODO: Refactor and move to visitor

.app-errors {
  grid-template-rows: minmax(20vh, max-content) auto min-content;
}

.header {
  background: white;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.27) 2px 2px 6px 2px;
  z-index: 2;

  h1 {
    font-weight: 700;
    font-size: 20px;
  }
}

.bottom-container {
  display: flex;

  .left-column {
    width: 50%;
    padding: 15vh;
    background: white;

    h2 {
      font-size: 28px;
      margin-bottom: 4vh;
    }

    p {
      font-size: 16px;
      margin-bottom: 4vh;
    }

    img {
      z-index: 1
    ;
    }
  }

  .visitor-error-image {
    width: 50%;
    height: 100%;
  }
}
