/**
 * Component Asset.
 *
 * @requires {mixin}  size
 * @requires {mixin}  text-truncate
 */

// =============================================================================
// Asset
// =============================================================================

.asset {
  opacity: 0;
  position: absolute;
  transition: {
    duration: 0.2s;
    property: opacity;
  }
}

/**
 * Modifiers.
 */

/**
 * Asset, link.
 */

.asset {
  &.--link {
    div {
      background-color: rgba(BLACK, 0.125);
      display: grid;
      place-items: center;

      a {
        @include text-truncate;

        background-color: rgba(WHITE, 0.75);
        border-radius: 5em;
        color: BLACK;
        font-weight: 700;
        min-width: 5em;
        padding: 1em 2em;
        transition: background-color 0.2s;

        &:focus,
        &:hover {
          background-color: rgba(WHITE, 1);
          text-decoration: none;
        }
      }
    }
  }
}

/**
 * States.
 */

/**
 * Asset, active.
 */

.asset {
  &.is-active {
    opacity: 1;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Asset inner wraper
// =============================================================================

/**
 * 1. This is just copypasta from index.html
 */

.asset__inset {
  height: 100%;
  transform-origin: 0 0;

  & > * {                 /* 1 */
    @include size(100%);

    user-select: none;
  }
}
