/**
 * Component Room.
 *
 * @requires {mixin} stretch
 */

// =============================================================================
// Scene / Room / Walls
// =============================================================================

.scene-wrapper {
  @include stretch;

  background-color: $blue--haiti;
  overflow: hidden;
  user-select: none;
}

.scene {
  position: absolute;
  left: 50%;
  top: 50%;
  transition: all 0.9s cubic-bezier(0.5, 0, 0.2, 1), perspective 1.5s cubic-bezier(0.5, -1, 0.5, 1);
}

.room {
  height: 100%;
  width: 100%;
  position: relative;
  transform-origin: 50% 50% 0;
  transform-style: preserve-3d;
  transition: all 0.9s cubic-bezier(0.5, 0, 0.2, 1);
}

.wall-wrapper {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  background: WHITE;
  transition: opacity 0.9s linear;
  opacity: 0.8;
  transform-origin: 0 50%;
  visibility: visible;

  &.wall-wrapper--2 {
    transform-origin: 100% 50%;
    left: auto;
    right: 0;
  }

  &.visible {
    opacity: 1;
  }
}
