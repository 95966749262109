/**
 * View Presenter.
 *
 * @requires {function} rhythm-get
 * @requires {mixin}    stretch
 * @requires {mixin}    size
 */

@import "../platform";
@import "../components/app.settings";

// =============================================================================
// Presenter view
// =============================================================================

[data-view="presenter"] {
  grid-template: {
    areas:
      "head    head head"
      "control body people"
    ;
    columns:
      300px
      auto
      300px
    ;
    rows:
      60px
      auto
    ;
  }
}

/**
 * Context.
 */

/**
 * App, with People sidebar.
 */

[data-view="presenter"] {
  &.has-people {
    .app__body {
      grid-column-end: 3;
    }
  }
}

/**
 * App, with Control sidebar.
 */

[data-view="presenter"] {
  &.has-control {
    .app__body {
      grid-column-start: 2;
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Header
// =============================================================================

[data-presenter="head"] {
  border: 0;
  grid-template-rows: inherit;
  padding: 0;
  position: relative;

  .app__inset {
    display: grid;
    gap: 0 1rem;
    grid: {
      template: {
        columns: repeat(3, 1fr);
        rows: inherit;
      }
    }
    width: 100%;

    & > div {
      display: flex;

      &:nth-child(2) {
        align-items: center;
        justify-self: center;
      }
      &:nth-child(3) {
        justify-content: flex-end;
        justify-self: end;
      }
    }
  }
}

[data-presenter="title"] {
  font: {
    size: 14px;
    weight: 500;
  }
  margin: 0;
}

// Body
// =============================================================================

[data-view="presenter"] {
  .app__body {
    grid-column: 1 / -1;
    padding: 0;
  }
}

// Sidebar
// =============================================================================

/**
 * Control sidebar.
 */

[data-sidebar="control"] {
  grid-area: control;
}

/**
 * People sidebar.
 */

[data-sidebar="people"] {
  font: {
    weight: 500;
  }
  grid-area: people;

  & > div {
    display: contents;
  }

  [class*="labels"] {
    .control {
      transform: scale(calc(2/3));
    }
  }
}

// Footer
// =============================================================================

// [data-view="presenter"] {}




// =============================================================================
//
// =============================================================================

// Video
// =============================================================================

[data-presenter="video"] {
  background-color: $blue--shark;
  position: relative;
  width: 105px;

  [data-video] {
    @include stretch;
  }

  [data-video="controls"] {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  [id] {
    background: none !important;
  }

  video {
    height: 100%;
    object-fit: cover;
  }
}

/**
 * States.
 */

/**
 * Video, hovered.
 */

[data-presenter="video"] {
  &:hover {
    [data-video="controls"] {
      opacity: 1;
      pointer-events: all;
    }
  }
}

[data-video="container"] {
  &.is-disabled {
    video {
      display: none;
    }
  }
}

[data-video="controls"] {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}


// Drawer
// =============================================================================

[data-presenter="drawer"] {
  $animation-props: (
    background-color,
    border-color,
    box-shadow,
    opacity,
    transform,
  );

  background-color: WHITE;
  border-bottom-right-radius: 8px;
  border-top: 1px solid $app-border;
  bottom: 1px;
  box-shadow: 0 1px 0 1px rgba($app-border, 0.5);
  opacity: 0;
  padding: 20px 2rem 0.5rem 1rem;
  pointer-events: none;
  position: absolute;
  transform: translate(-100%, 100%);
  transform-origin: 0 0;
  transition: {
    duration: 0.3s;
    property: join($animation-props, ());
    timing-function: map-get($animation-easing, in-out-cubic);
  }
  width: #{"min(100%, 40em)"};
  will-change: join($animation-props, ());
  z-index: 3;

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transform: translate(0%, 100%);
  }

  &.is-mute {
    background-color: rgba(WHITE, 0);
    border-top-color: transparent;
    box-shadow: 0 0 rgba($app-border, 0);

    & > :not([data-drawer="open-control"]) {
      opacity: 0;
    }
  }
}

// Drawer / Open Control
// =============================================================================

[data-drawer="open-control"] {
  cursor: zoom-in;
  margin-top: rhythm-get(1);
  transform-origin: -10% 5%;
  transition: {
    duration: 0.3s;
    property: transform;
    timing-function: map-get($animation-easing, in-out-cubic);
  }
  will-change: transform;
}

/**
 * States.
 */

/**
 * Drawer / Open Control, active.
 */

[data-drawer="open-control"] {
  &.is-active {
    cursor: zoom-out;
    transform: scale(3);
  }
}

// Clipboard
// =============================================================================

[data-presenter="clipboard"] {
  &,
  [type="text"],
  .control[class] {
    cursor: copy;
  }

  [type="text"] {
    background: none;
    border: 0;
    flex: 1;
    padding: 0;
  }

  .control {
    margin-left: 0.5em;
  }
}

// Shared video stream
// =============================================================================

// [data-presenter="video"] {
//   @at-root .panel__item & {
//     display: contents;
//   }

//   @at-root .app__body & {
//     &,
//     &::before {
//       position: absolute;
//     }

//     video {
//       @include size(80%, auto);
//     }

//     .control-group {
//       position: absolute;
//     }
//   }
// }


// FIXME: rewrite
.quit-session-button {
  padding: 0 16px 0 0;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 34px;

  svg {
    font-size: 36px;
    margin-right: 8px;
    left: -2px;
    position: relative;
  }
}

.quit-session-button:hover {
  color: white;
  background: #004aff;

  svg {
    color: white;
  }
}

.control-button {
  margin: 0;
  padding: 0 16px 0 0;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  background: #e5e3e3;

  svg {
    left: -2px;
    position: relative;
    margin-right: 8px;
    font-size: 34px;
    transition: transform ease-in-out 0.5s;
  }
}

.control-button:hover {
  color: white;
  background: #004aff;

  svg {
    color: white;
  }
}

.control-button.is-active {
  color: white;
  background: #004aff;

  svg {
    transform: rotate(-180deg);
    color: white;
  }
}

.control-button.is-active:hover {
  color: #004aff;
  background: #e5e3e3;

  svg {
    color: #004aff;
  }
}
// FIXME: end







// Control
// TODO: Refactor Control
// =============================================================================

.control {
  --element-size: 30px;
  --icon-scale: 1;
  --icon-size: 16px;

  &.--large {
    --element-size: 46px;
    --icon-scale: 1;
    --icon-size: 24px;
  }
}

.control__element {
  border: 0;
}

/**
 * Modifiers.
 */

[data-control="audio"],
[data-control="ban"],
[data-control="cam"],
[data-control="dock"],
[data-control="mic"],
[data-control="speech"] {
  --background-color: #{rgba($blue--fiord, 0.6)};
  --color: WHITE;

  &[class][class][class] {
    &:focus,
    &:hover,
    &.is-active {
      --color: WHITE;
    }
  }
}

[data-control="dock"],
[data-control="expand"] {
  &[class][class][class] {
    &:focus,
    &:hover {
      --background-color: #{rgba($blue--fiord, 1)};
    }
  }
}

[data-control="audio"],
[data-control="ban"],
[data-control="cam"],
[data-control="mic"],
[data-control="speech"] {
  &[class][class][class] {
    &:focus,
    &:hover,
    &.is-active {
      --background-color: #{$red--sunset};
    }
  }
}

[data-control="play"] {
  --background-color: #{$gray--gallery};
  --color: #{$blue--ribbon};

  &[class][class][class] {
    &:focus,
    &:hover {
      --background-color: #{$green--shamrock};
      --color: WHITE;
    }
  }
}

[data-control="stop"] {
  --background-color: #{$blue--ribbon};
  --color: WHITE;

  &[class][class][class] {
    &:focus,
    &:hover {
      --background-color: #{$red--sunset};
      --color: WHITE;
    }
  }
}

// Open Control
// =============================================================================

[data-presenter="open-control"] {
  display: grid;
  gap: 0 2em;
  grid-template-columns: min-content auto;
}

// Toggle
// =============================================================================

[data-toggle] {
  cursor: pointer;
  position: relative;

  svg {
    @include size(var(--icon-size, 24px));
  }

  .badge {
    position: absolute;
    right: 8px;
    top: 16px;
  }
}

/**
 * Modifiers.
 */

[data-toggle="chat"],
[data-toggle="menu"],
[data-toggle="people"],
[data-toggle="share"] {
  background: transparent;
  border: 0;
  display: grid;
  padding: 0;
  place-items: center;
  transition: {
    duration: 0.3s;
    property:
      background-color,
      color
    ;
  }
  width: 60px;

  &:focus,
  &:hover,
  &.is-active {
    background-color: $gray--gallery;
    color: $blue--ribbon;
  }
}

[data-toggle="control"] {
  bottom: -50px;
  left: 1rem;
  position: absolute;
  z-index: 1;
}

[data-toggle="share"] {
  position: relative;

  .control {
    --background-color: #{$red--sunset};
    --icon-size: 16px;

    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
