/**
 * Component List / Chat.
 *
 * @requires {function}    rhythm-get
 * @requires {mixin}       word-wrap
 * @requires {placeholder} %list--reset
 * @requires list
 */

// =============================================================================
// Chat list
// =============================================================================

.list-chat {
  --list-chat-title-color: #{$list-chat-item-title-color};

  @extend %list--reset !optional;

  font-size: 12px;
}

// =============================================================================
// Subcomponents
// =============================================================================

// List item
// =============================================================================

.list-chat__item {
  margin-top: map-get($list-chat-item-indent, y);

  &[class] {
    padding: {
      left: map-get($list-chat-item-indent, x);
      right: map-get($list-chat-item-indent, x);
    }
  }
}

/**
 * Modifiers.
 */

/**
 * List item, primary.
 */

.list-chat__item {
  &.--primary {
    --list-chat-title-color: #{$list-chat-item--primary-title-color};
  }
}

// List item head
// =============================================================================

.list-chat__item__head {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}

// List item body
// =============================================================================

.list-chat__item__body {
  @include word-wrap;
}

// List item title
// =============================================================================

.list-chat__item__title {
  color: var(--list-chat-title-color);
  font-weight: 600;
  margin: 0;
  margin-right: 1em;
}

// List item metadata
// =============================================================================

.list-chat__item__meta {
  color: $list-chat-item-meta-color;
  flex-shrink: 0;
  font: {
    size: 11px;
    width: 500px;
  }
}
