/**
 * Main.
 */

// =============================================================================
// Main
// =============================================================================

// Imports settings, libs, frameworks, layers, helpers and compoments.
// ORDER DOES MATTER!

@import-normalize;

// Libs, Frameworks
// =============================================================================

// @import "mathsass";
@import "~react-toastify/dist/ReactToastify.css";
@import "~rc-tooltip/assets/bootstrap_white.css";

// Settings
// =============================================================================

@import "layers/color.settings";
@import "layers/base.settings";
// @import "layers/breakpoints.settings";

@import "components/animation.settings";
@import "components/app.settings";
@import "components/avatar.settings";
@import "components/backdrop.settings";
@import "components/badge.settings";
@import "components/button.settings";
@import "components/button.group.settings";
@import "components/card.settings";
@import "components/container.settings";
@import "components/control.settings";
@import "components/counter.settings";
@import "components/cursor.settings";
@import "components/dialog.settings";
@import "components/element.settings";
@import "components/form.element.settings";
@import "components/form.message.settings";
@import "components/form.select.settings";
@import "components/form.textarea.settings";
@import "components/grid.settings";
@import "components/hint.settings";
@import "components/icon.settings";
@import "components/indicator.progress.settings";
@import "components/label.settings";
@import "components/message.settings";
@import "components/notification.toast.settings";
@import "components/tag.settings";
@import "components/tag.group.settings";

// Helpers
// =============================================================================

@import "helpers/functions";
@import "helpers/mixins";
@import "helpers/extends";

// Layers
// =============================================================================

@import "layers/base";

// Components
// =============================================================================

@import "components/app";
@import "components/animation.fade";
@import "components/asset";
@import "components/avatar";
@import "components/backdrop";
@import "components/badge";
@import "components/button";
@import "components/button.group";
@import "components/card";
@import "components/container";
@import "components/control";
@import "components/counter";
@import "components/cursor";
@import "components/dialog";
@import "components/element";
@import "components/form";
@import "components/form.element";
@import "components/form.message";
@import "components/form.select";
@import "components/form.textarea";
@import "components/grid";
@import "components/hint";
@import "components/icon";
@import "components/indicator.progress";
@import "components/label";
@import "components/list";
@import "components/message";
@import "components/notification.toast";
@import "components/room";
@import "components/table";
@import "components/tag";
@import "components/tag.group";
@import "components/wall";

// Views
// =============================================================================

// @import "views/control";

// Layers [high priority]
// =============================================================================

@import "layers/utility";



// =============================================================================
// ¯\(°_o)/¯ HAMMER TIME! ¯\(°_o)/¯
// =============================================================================

[id="version"] {
  @include stretch(fixed, auto, auto, 1rem, 1rem);

  color: #c2c2c2;
  font-size: 10px;
}
