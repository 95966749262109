/**
 * Component List / Asset.
 *
 * @requires {mixin} size
 * @requires {mixin} stretch
 * @requires {mixin} text-truncate
 */

// =============================================================================
// Assets list
// =============================================================================

.list-asset {}

// =============================================================================
// Subcomponents
// =============================================================================

// List item
// =============================================================================

.list-asset__item {
  animation: list-asset__item 0.3s map-get($animation-easing, out-cubic) both;
  border: 3px solid map-get($list-asset-item-border, initial);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s;
  user-select: none;

  &::after {
    @include stretch;

    background-image: linear-gradient(0deg, rgba(BLACK, 0.75), transparent 50%);
    content: "";
  }
  &::before {
    @include stretch(-8px);

    border: 2px solid transparent;
    border-radius: inherit;
    content: "";
    transition: inherit;
  }

  img {
    @include size(100%);

    display: block;
    object-fit: cover;
  }
}

/**
 * States.
 */

/**
 * List item, active.
 */

.list-asset__item {
  &.is-active {
    &::before {
      border-color: map-get($list-asset-item-border, active);
    }
  }
}

/**
 * List item, visible.
 */

.list-asset__item {
  &.is-visible {
    border-color: map-get($list-asset-item-border, visible);
  }
}

/**
 * Animations.
 */

@keyframes list-asset__item {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// List item title
// =============================================================================

.list-asset__title {
  @include stretch(auto, 0, 0, 0);
  @include text-truncate;

  font-size: 0.8rem;
  color: WHITE;
  display: block;
  padding: 0.75em 1em;
  z-index: 1;
}

// List item figure
// =============================================================================

.list-asset__figure {
  @include size(2em);

  --background: #{map-get($list-asset-figure-background, initial)};

  background-color: var(--background);
  border-radius: 50%;
  color: WHITE;
  display: grid;
  font: {
    size: 0.9rem;
    weight: 500;
  }
  line-height: 1;
  place-items: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  transition: background-color 0.3s;
}

/**
 * States.
 */

/**
 * List item figure, active.
 */

.list-asset__figure {
  &.is-active {
    --background: #{map-get($list-asset-figure-background, active)};
  }
}
