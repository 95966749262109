/**
 * Component Tag.
 *
 * @requires {mixin} size
 * @requires animation.fade
 */

// =============================================================================
// Tag
// =============================================================================

.tag {
  --background: #{$tag-background};
  --color: #{$tag-color};

  align-items: center;
  animation: fade--in 0.3s both;
  background-color: var(--background);
  border-radius: 1000px;
  color: var(--color);
  display: inline-flex;
  font: {
    size: px-to-rem($tag-font-size);
    weight: 500;
  }
  justify-content: space-between;
  line-height: initial;
  max-width: 100%;
  padding:
    map-get($tag-indent, y)
    map-get($tag-indent, x)
  ;
  user-select: none;
}

/**
 * Modifiers.
 */

/**
 * Tag, color variants.
 */

.tag {
  @each $key, $color in $tag-theme {
    &.--#{$key} {
      --background: #{$color};
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Tag control
// =============================================================================

.tag__control {
  background: none;
  border: 0;
  cursor: pointer;
  display: grid;
  margin: {
    left: 4px;
    right: -6px;
  }
  padding: 0;
  place-items: center;
  transition: opacity 0.3s;

  &,
  svg {
    @include size($tag-control-size);
  }
}

/**
 * States.
 */

/**
 * Tag control, hovered.
 */

.tag__control {
  &:focus,
  &:hover {
    opacity: 0.5;
  }
}
