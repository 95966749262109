/**
 * Component List / Asset settings.
 */

// =============================================================================
// Assets list settings
// =============================================================================

// Set list item border color map.
$list-asset-item-border: (
  active:     $blue--ribbon,     // pressed
  disable:    transparent,       // disabled
  focus:      transparent,       // focused
  hover:      transparent,       // hovered
  initial:    transparent,
  visible:    $green--caribbean, // visible
);

// Set list figure background color map.
$list-asset-figure-background: (
  active:     $blue--ribbon,     // pressed
  disable:    transparent,       // disabled
  focus:      transparent,       // focused
  hover:      transparent,       // hovered
  initial:    $blue--haiti,
);
