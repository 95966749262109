/**
 * Component Form / Message.
 *
 * @requires animation.fade
 */

// =============================================================================
// Form message
// =============================================================================

.form-message {
  --color: currentColor;

  animation: fade--in 0.3s both;
  color: var(--color);
  display: inline-block;
  font-size: 0.9rem;
  padding: 0 calc(1em + 1px);
  padding-top: 0.35em;
  text-transform: uppercase;
}

/**
 * Modifiers.
 */

/**
 * Message, color variants.
 */

.form-message {
  @each $key, $c in $form-message-color {
    &.--#{$key} {
      $color: rgba($c, 1);

      --color: #{$color};
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
