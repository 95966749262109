/**
 * Component App settings.
 */

// =============================================================================
// App settings
// =============================================================================

// Set app background color.
$app-background: WHITE;

// Set app border color.
$app-border: $gray--gallery;

// Set app breakpoints.
$app-breaks: (
  480px,
);

// Set app features.
$app-features: (
  debug: false,
);

// Set app indent.
$app-indent: (
  x: 5vmin,
  y: 2rem,
);

// Set app `z-index`.
$app-index: 1;

// Set app size.
$app-size: (
  max: 1600px,
  min: 320px,
);

/**
 * App header.
 */

// Set app header size.
$app-head-size: (
  max: max-content,
  min: calc(
    #{2 * 0.5 * map-get($app-indent, y)}
    + 34px  // according to $button-size
  ),
);

/**
 * App sidebar.
 */

// Set app sidebar background color.
$app-sidebar-background: $app-background;
