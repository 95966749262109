/**
 * Component Wall.
 *
 * @requires {function}    function
 * @requires {mixin}       stretch
 * @requires {placeholder} %placeholder
 * @requires {variable}    $variable
 * @requires component
 */

// =============================================================================
// Wall
// =============================================================================

.wall {

  $size: 50vh;

  --wall-height: 1;
  --wall-ratio: 1;
  --wall-width: 1;

  background-color: WHITE;
  box-shadow: 0 5px 10px 5px rgba(BLACK, 0.125);
  display: none;
  max-height: $size;
  margin: auto;
  overflow: hidden;
  position: relative;
  max-width: calc(var(--wall-ratio) * #{$size});
  // visibility: hidden;

  &::before {
    content: "";
    display: block;
    max-height: inherit;
    padding-bottom: calc(100% * 1 / var(--wall-ratio));
  }

  & > :first-child {
    @include stretch;
  }
}

/**
 * Modifiers.
 */

// .component--modifier {}

// .component {
//   &.--modifier {}
// }

/**
 * States.
 */

/**
 * Wall, active.
 */

.wall {
  &.is-active {
    display: block;
    // visibility: visible;
  }
}

/**
 * Context.
 */

// .has-component {}

/**
 * Animations.
 */

// @keyframes component-animation {}

// =============================================================================
// Subcomponents
// =============================================================================

// Wall item
// =============================================================================

.wall__item {
  // position: absolute;
  transform-origin: 0 0;
  visibility: hidden;

  img,
  video {
    display: block;
  }
}

/**
 * States.
 */

/**
 * Wall item, active.
 */

.wall__item {
  &.is-active {
    visibility: visible;
  }
}
