/**
 * Component List / Chat settings.
 */

// =============================================================================
// Chat list settings
// =============================================================================

// Set list item indent.
$list-chat-item-indent: (
  x: 16px,
  y: 24px,
);

/**
 * List item.
 */

// Set item title color.
$list-chat-item-title-color: $blue--dodger;

// Set primary item title color.
$list-chat-item--primary-title-color: $blue--ribbon;

// Set item metadata color.
$list-chat-item-meta-color: $gray--french;
