/**
 * Layer Base settings.
 */

// =============================================================================
// Base settings
// =============================================================================

// Set base background color.
$base-background: $gray--alabaster;

// Set base text color.
$base-color: $blue--haiti;

// Set base font family stack.
$base-font-stack: ("Inter", sans-serif);

// Set base font size.
$base-font-size: 13px;

// Set browser default font size.
$base-font-size--browser: 16px;

// Set base font weight.
$base-font-weight: 400;

// Set base line height.
$base-line-height: 20px;

// Set base line height ratio.
$base-line-height-ratio: $base-line-height / $base-font-size;

// Set base rhythm unit.
$base-rhythm-unit: 0.5;

// Set base scale.
// https://type-scale.com/?size=13&scale=1.125
$base-scale: (
  1: 1.802,
  2: 1.602,
  3: 1.424,
  4: 1.266,
  5: 1.125,
  6: 1.000,
  7: 0.889,
);

// Set selection background.
$base-selection-background: rgba(GOLD, 0.5);
