/**
 * Component Dialog settings.
 */

// =============================================================================
// Dialog settings
// =============================================================================

// Set dialog background.
$dialog-background: WHITE;

// Set dialog backdrop color.
$dialog-backdrop-color: BLACK;

// Set dialog border color.
$dialog-border-color: $gray--gallery;

// Set dialog border radius.
$dialog-border-radius: 4px;

// Set dialog font size.
$dialog-font-size: 13px;

// Set app features.
$dialog-features: (
  debug: false,
);

// Set dialog indent.
$dialog-indent: (
  x: 16px,
  y: 10px,
);

// Set dialog z-index.
$dialog-index: 1;

// Set dialog title font size.
$dialog-title-font-size: 14px;

// Set dialog control color map.
$dialog-control-color: (
  active:     $blue--ribbon,   // pressed
  disable:    initial,         // disabled
  focus:      $blue--ribbon,   // focused
  hover:      $blue--ribbon,   // hovered
  initial:    $gray--manatee,
);

// Set dialog control size.
$dialog-control-size: 22px;

// Set dialog control icon size.
$dialog-control-icon-size: 16px;
