/**
 * Component Dialog.
 *
 * @requires {mixin}   stretch
 * @requires animation.fade
 */

// =============================================================================
// Dialog
// =============================================================================

.dialog {
  @include stretch(fixed, 0);

  display: none;
  font-size: px-tp-rem($dialog-font-size);
  padding: 1rem;
  place-items: start center;
  z-index: $dialog-index;
}

/**
 * States.
 */

/**
 * Dialog, active.
 */

.dialog {
  &.is-active {
    display: grid;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Backdrop
// =============================================================================

%dialog__backdrop {
  animation: fade--in 0.3s both;
  background-color: rgba($dialog-backdrop-color, 0.5);
  content: "";
  z-index: -1;
}

.dialog__backdrop {
  &,
  &::before {
    @include stretch(fixed, 0);
  }
  &::before {
    @extend %dialog__backdrop;
  }
}

// Stage
// =============================================================================

.dialog__stage {
  animation: dialog 0.3s map-get($animation-easing, out-cubic) 0.1s both;
  background-color: $dialog-background;
  border-radius: $dialog-border-radius;
  box-shadow: 0 1px 0 rgba($dialog-backdrop-color, 0.125);
  display: grid;
  grid-template-rows:
    min-content
    minmax(auto, 100%)
    min-content
  ;
  max-height: 60vh;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  top: 15vmin;
  width: #{"min(100%, 450px)"};
  z-index: 1;
}

/**
 * Animations.
 */

@keyframes dialog {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }
  50% {
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}

// Inset
// =============================================================================

%dialog__inset {
  min-height: 40px;
  padding:
    map-get($dialog-indent, y)
    map-get($dialog-indent, x)
  ;
}

.dialog__inset {
  @extend %dialog__inset;

  margin: 0 ((-1) * map-get($dialog-indent, x));

  & + & {
    border-top: 1px solid $dialog-border-color;
  }
  &:first-child {
    margin-top: (-1) * map-get($dialog-indent, y);
  }
  &:last-child {
    margin-bottom: (-1) * map-get($dialog-indent, y);
  }
}

// Header
// =============================================================================

.dialog__head {
  @extend %dialog__inset;

  align-items: center;
  border-bottom: 1px solid $dialog-border-color;
  display: flex;
  justify-content: space-between;

  @if map-get($dialog-features, debug) {
    background-color: rgba(TOMATO, 0.75);
  }
}

// Body
// =============================================================================

.dialog__body {
  @extend %dialog__inset;

  overflow-y: auto;

  @if map-get($dialog-features, debug) {
    background-color: rgba(GOLD, 0.75);
  }
}

// Footer
// =============================================================================

.dialog__foot {
  @extend %dialog__inset;

  display: flex;
  justify-content: flex-end;

  [class*="button"] {
    margin-bottom: 0;
  }

  @if map-get($dialog-features, debug) {
    background-color: rgba(MEDIUMSLATEBLUE, 0.75);
  }
}

// Title
// =============================================================================

.dialog__title {
  color: BLACK;
  font-size: px-to-em(
    $dialog-title-font-size,
    $dialog-font-size
  );
  font-weight: 500;
  margin: 0;

  @if map-get($dialog-features, debug) {
    background-color: rgba(AQUAMARINE, 0.75);
  }
}

// Control
// =============================================================================

.dialog__control {
  @include size($dialog-control-size);

  background: none;
  border: 0;
  color: map-get($dialog-control-color, initial);
  display: grid;
  flex-shrink: 0;
  padding: 0;
  place-items: center;
  transition: color 0.3s;

  svg {
    @include size($dialog-control-icon-size);
  }

  @if map-get($dialog-features, debug) {
    background-color: rgba(PINK, 0.75);
  }
}

/**
 * Modifiers.
 */

/**
 * People control, close.
 */

.dialog__control {
  &.--close {
    margin: {
      bottom: -2px;
      right: -4px;
      top: -2px;
    }
  }
}

/**
 * States.
 */

/**
 * Control, hovered.
 */

.dialog__control {
  &:focus,
  &:hover {
    color: map-get($dialog-control-color, hover);
  }
}
