/**
 * Component Table.
 *
 * @requires {function}    rhythm-get
 */

// =============================================================================
// Table
// =============================================================================

.table {

  --row-background: transparent;

  color: #080808;

  margin: {
    bottom: rhythm-get(1);
    top: rhythm-get(1);
  }
  width: 100%;

  thead {
    font-size: 0.9em;

    th {
      color: #c2c2c2;
      padding: {
        bottom: 0.5em;
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        td {
          border-color: transparent;
        }
      }

      &:hover {
        td {
          --row-background: #e1e1e1;
        }
      }
    }

    td {
      position: relative;

      &::before {
        @include stretch(-1px, 0);

        background-color: var(--row-background);
        content: "";
        transition: background-color 0.6s;
        z-index: -1;
      }
    }
  }

  td,
  th {
    border-bottom: 1px solid #e1e1e1;
    padding: {
      bottom: 1.5em;
      right: 1em;
      top: 1.5em;
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
