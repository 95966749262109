/**
 * Component Notification / Toast.
 *
 * `react-toastify` overrrides!
 * @see https://github.com/fkhadra/react-toastify
 *
 * @requires {function}    function
 * @requires {mixin}       mixin
 * @requires {placeholder} %placeholder
 * @requires {variable}    $variable
 * @requires component
 */

// =============================================================================
// Toast notification
// =============================================================================

[class*="toast "] {

  --background: WHITE;

  &[class] {
    background: var(--background);
    border-radius: 2px;
    font-family: inherit;
    padding: 1rem 1.5rem;
  }
}

/**
 * Modifiers.
 */

/**
 * Notification, color variants.
 */

[class*="toast "] {
  @each $key, $c in $notification-toast-color {
    &[class*="#{$key}"] {
      --background: #{rgba($c, 0.95)};
    }
  }
}

/**
 * States.
 */

// .is-component-state {}

// .component {
//   &.is-state {}
// }

/**
 * Context.
 */

// .has-component {}

/**
 * Animations.
 */

// @keyframes component-animation {}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
