/**
 * Component Indicator/Progress.
 *
 * @requires {function}    rhythm-get
 * @requires {mixin}       stretch
 * @requires {variable}    $animation-easing
 */

// =============================================================================
// Indicator
// =============================================================================

.indicator-progress {
  $indent: 0.35 * $indicator-progress-indent;

  --font-size: 0.9rem;
  --progress: 0;

  color: $indicator-progress-color;
  display: block;
  font-size: var(--font-size);
  padding: $indent 0;
  position: relative;
  z-index: $indicator-progress-index;

  & + & {
    margin-top: rhythm-get();
  }
}

/**
 * Modifiers.
 */

/**
 * Indicator, primary.
 */

.indicator-progress {
  &.--primary {
    $indent: 0.5 * $indicator-progress-indent;

    --font-size: 1rem;

    padding: {
      bottom: $indent;
      top: $indent;
    }

    .indicator-progress__body {
      display: block;
    }
  }
}

/**
 * Context.
 */

/**
 * Indicator, with title.
 */

.indicator-progress {
  &.has-title {
    margin-top: 3 * $indicator-progress-indent;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Indicator scale
// =============================================================================

.indicator-progress__scale {
  background-color: rgba($indicator-progress-color, 0.5);
  border-radius: $indicator-progress-border-radius;
  overflow: hidden;
  will-change: transform;

  &,
  &::before {
    @include stretch;

    display: inherit;
  }

  &::before {
    background-color: currentColor;
    content: "";
    transform: scaleX(calc(var(--progress) / 100));
    transform-origin: 0 50%;
    transition: transform 0.3s map-get($animation-easing, in-out-cubic);
  }
}

// Indicator head
// =============================================================================

.indicator-progress__title {
  @include text-truncate;

  color: $indicator-progress-color;
  font-size: 0.9em;
  position: absolute;
  text-transform: uppercase;
  top: (-2.125) * $indicator-progress-indent;
}

// Indicator body
// =============================================================================

.indicator-progress__body {
  align-items: center;
  color: $indicator-progress-body-color;
  display: flex;
  justify-content: space-between;
  padding: 0 $indicator-progress-indent;
  position: relative;
}
