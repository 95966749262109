/**
 * Component Badge.
 *
 * @requires {mixin} text-truncate
 */

// =============================================================================
// Badge
// =============================================================================

.badge {
  --background-color: #{$badge-background};
  --color: #{$badge-color};

  @include text-truncate;

  $tracking: $badge-letter-spacing / 1000 * 1em;

  background-color: var(--background-color);
  border-radius: $badge-border-radius;
  color: var(--color);
  display: inline-grid;
  flex: 0;
  font: {
    size: $badge-font-size;
    weight: $badge-font-weight;
  }
  letter-spacing: $tracking;
  line-height: 1;
  min-width: 1.35em;
  padding: $badge-indent;
  place-items: center;
}

/**
 * Modifiers.
 */

/**
 * Badge, themes.
 */

/**
 * Badge, color variants.
 */

.badge {
  @each $key, $color in $badge-color {
    &.--#{$key} {
      --background-color: #{$color};
      --color: WHITE;
    }
  }
}
