/**
 * View Presenter / People.
 *
 * @requires animation.fade
 */

@import "../platform";
@import "../components/list.chat.settings";
@import "../components/list.user.settings";

@import "../components/list";
@import "../components/list.chat";
@import "../components/list.user";

// Set border color.
$people-border-color: $gray--gallery;

// Set features.
$people-features: (
  debug: false,
);

// Set people font size.
$people-font-size: $base-font-size;

// Set indent.
$people-indent: (
  x: 16px,
  y: 9px,
);

/**
 * Metadata.
 */

// Set metadata color.
$people-metadata-color: $gray--french;

// =============================================================================
// People view
// =============================================================================

.people {
  animation: fade--in 0.3s both;
  display: grid;
  font: {
    size: px-to-rem($people-font-size);
  }
  grid-template: {
    areas:
      "head"
      "body"
      "foot"
    ;
    rows:
      min-content
      auto
      min-content
    ;
  }
  height: 100%;
}

// =============================================================================
// Subcomponents
// =============================================================================

// People inset
// =============================================================================

%people__inset {
  padding:
    map-get($people-indent, y)
    map-get($people-indent, x)
  ;
  // position: relative;
}

// People header
// =============================================================================

.people__head {
  --icon-size: 24px;

  div {
    @extend %people__inset;

    &:nth-of-type(1) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    &:nth-of-type(2) {
      display: grid;
      gap: 0 map-get($people-indent, x);
      grid-template-columns: repeat(2, 1fr);
    }
  }

  div + div {
    border-top: 1px solid $people-border-color;
  }

  @if map-get($people-features, debug) {
    background-color: rgba(TOMATO, 0.75);
  }
}

// People body
// =============================================================================

.people__body {
  overflow-y: auto;
}

// People footer
// =============================================================================

.people__foot {
  background-color: WHITE;
  box-shadow: 0 -4px 9px rgba($gray--alto, 0.25);
  padding: map-get($people-indent, x);

  @if map-get($people-features, debug) {
    background-color: rgba(GOLD, 0.75);
  }
}

// People title
// =============================================================================

.people__title {
  align-items: baseline;
  color: BLACK;
  display: flex;
  font-size: px-to-em(14px, $people-font-size);
  margin: 0;
}

// People metadata
// =============================================================================

.people__meta {
  color: $people-metadata-color;
  font-size: px-to-em(12px, $people-font-size);
  margin-left: 0.5em;
}

// People control
// =============================================================================

.people__control {
  align-items: center;
  background: none;
  border: 0;
  display: flex;
  font-size: px-to-em(13px, $people-font-size);
  justify-content: center;
  padding: 0;
  transition: color 0.3s;

  svg {
    @include size(var(--icon-size));
  }
}

/**
 * Modifiers.
 */

/**
 * People control, close.
 */

.people__control {
  &.--close {
    --icon-size: 16px;

    @include size(30px);

    color: $gray--manatee;
    margin: {
      bottom: -2px;
      right: -10px;
      top: -2px;
    }
  }
}

/**
 * States.
 */

/**
 * People control, hovered.
 */

.people__control {
  &:focus,
  &:hover {
    color: $blue--ribbon;
  }
}
