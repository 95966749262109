/**
 * View Controller.
 */

@import "../platform";
@import "../components/list.asset.settings";
@import "../components/list.asset";

// Set border color.
$controller-border-color: $gray--gallery;

// Set features.
$controller-features: (
  debug: false
);

// Set indent.
$controller-indent: (
  x: 1rem,
  y: 1rem
);

// =============================================================================
// Controller view
// =============================================================================

.controller {
  display: grid;
  grid-template: {
    areas: "head" "body" "foot";
    rows: min-content auto min-content;
  }
  height: 100%;
  padding-bottom: 2.5rem;

  .control {
    transform: scale(0.9);
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// Controller inset
// =============================================================================

%controller__inset {
  padding: map-get($controller-indent, y) map-get($controller-indent, x);
  position: relative;
}

// Controller header
// =============================================================================

.controller__head {
  @extend %controller__inset;

  border-bottom: 1px solid $controller-border-color;
  padding-top: 50px;
  width: 300px;

  @if map-get($controller-features, debug) {
    background-color: rgba(TOMATO, 0.75);
  }
}

// Controller body
// =============================================================================

.controller__body {
  @extend %controller__inset;

  grid-area: body;
  // flex: 1;
  overflow-y: auto;
}

// Controller footer
// =============================================================================

.controller__foot {
  @extend %controller__inset;

  padding: 1rem 2rem;
  align-items: center;
  border-top: 1px solid $controller-border-color;
  display: grid;
  grid: {
    area: foot;
    gap: 0 0.5rem;
    template: {
      areas: "a b c d";
      columns: minmax(70px, min-content) auto auto min-content;
      rows: minmax(60px, min-content);
    }
  }

  p {
    margin: 0;
  }

  .button {
    margin-bottom: 0;
  }

  .control {
    position: relative;
  }
  .control__label {
    bottom: 0;
    position: absolute;
    transform: translateY(calc(100% + 5px));
  }

  @if map-get($controller-features, debug) {
    background-color: rgba(GOLD, 0.75);
  }
}

// Controller title
// =============================================================================

.controller__title {
  font: {
    size: 1rem;
    weight: 400;
  }
  margin: rhythm-get() 0;
  padding-top: rhythm-get();
}

// Assets list
// =============================================================================

.controller {
  .list-asset {
    --grid-column-min: 100px;
    --grid-gap-column: 8px;
    --grid-gap-row: 8px;
    --grid-sizing: auto-fill;

    grid-template-rows: repeat(auto-fit, #{"max(auto, 100px)"});
  }
}

// Volume control
// =============================================================================

[data-control="volume"] {
  position: relative;
  z-index: 1;
}

[data-range="volume"] {
  align-items: center;
  background-color: WHITE;
  border: 1px solid $controller-border-color;
  border-radius: 20px;
  display: flex;
  padding: 8px 16px;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 50%;
  margin-top: 0.3rem;

  svg {
    margin-right: 8px;
  }
}

/**
 * Slider.
 */

.rc-slider {
  --color: #{$blue--ribbon};
}

.rc-slider-track {
  &[class][class] {
    background-color: var(--color);
  }
}

.rc-slider-handle {
  &[class][class] {
    border-color: var(--color);
    box-shadow: none;
  }
}

// Wall switch
// =============================================================================

[data-switch="wall"] {
  position: relative;
  z-index: 1;

  .control:not([data-switch="master"]) {
    left: 0;
    opacity: 0;
    position: absolute;
    transition: {
      duration: 0.3s;
      property: opacity, transform;
      timing-function: map-get($animation-easing, in-out-cubic);
    }
  }

  [data-switch="master"] {
    position: relative;
    z-index: 1;
  }
}

/**
 * Switch, active.
 */

[data-switch="wall"] {
  &.is-active {
    .control:not([data-switch="master"]) {
      opacity: 1;

      @for $i from 1 through 10 {
        &:nth-child(#{$i + 1}) {
          transform: scale(0.9)
            translateY(calc(#{(-1) * ($i + 1)} * 120% + 80%));
        }
      }
    }
  }
}

// Asset filter
// =============================================================================

[data-filter="asset"] {
  margin: 0;
  position: absolute;
  right: map-get($controller-indent, x);
  top: 0;
  transform: translateY(-60px) translateX(-25px);
  z-index: 1;

  .button {
    border-radius: 30px;
    border: none;
    bottom: 0;
    width: 3rem;
    height: 3rem;
    opacity: 0;
    position: absolute;
    box-shadow: rgba(142, 143, 153, 0.33) 0 0 4px 2px;
    right: 0;
    transition: {
      duration: 0.3s;
      property: opacity, transform;
      timing-function: map-get($animation-easing, in-out-cubic);
    }

    svg {
      font-size: 1.45rem;
      color: black;
    }
  }

  .button.is-active {
    svg {
      color: white;
    }
  }

  .button:hover {
    svg {
      color: white;
    }
  }

  [data-filter="master"] {
    position: relative;
    z-index: 1;
  }
}

/**
 * Filter, active.
 */

[data-filter="asset"] {
  &.is-active {
    .button {
      opacity: 1;

      @for $i from 1 through 5 {
        &:nth-child(#{$i + 1}) {
          transform: translateY(calc(#{(-1) * ($i + 1)} * 120% + 90%));
        }
      }
    }
  }
}
