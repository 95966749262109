/**
 * Component Icon.
 */

// =============================================================================
// Icon
// =============================================================================

/**
 * 1. Override FontAwesome props.
 */

.ico {
  &[class] {
    height: calc(1em * #{$base-line-height-ratio}); /* 1 */
    vertical-align: initial;                        /* 1 */
    width: calc(1em * #{$base-line-height-ratio});  /* 1 */
  }
}

/**
 * Modifiers.
 */

/**
 * Icon, after.
 */

.ico {
  &.--end {
    margin-left: $icon-offset;
  }
}

/**
 * Icon, before.
 */

.ico {
  &.--start {
    margin-right: $icon-offset;
  }
}

/**
 * Icon, color variants.
 */

.ico {
  @each $key, $color in $icon-color {
    &.--#{$key} {
      color: $color;
    }
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
