/**
 * Component Form/Element.
 *
 * @requires {placeholder} %element
 * @requires {placeholder} %element--large
 * @requires element
 */

// =============================================================================
// Form element
// =============================================================================

%form-element {
  @extend %element !optional;
  @extend %element--large !optional;

  align-items: center;
  background-color: map-get($form-element-background, initial);
  border: $form-element-border-size solid map-get($form-element-border-color, initial);
  border-radius: $form-element-border-radius;
  color: map-get($form-element-color, initial);
  display: inline-flex;
  padding: 0 $form-element-indent;
  transition: {
    duration: 0.2s;
    property:
      background-color,
      border-color,
      color,
      width,
    ;
  }
  width: 100%;
}

.form-element {
  @extend %form-element;
}

/**
 * Modifiers.
 */

/**
 * Form element, transparent.
 */

%form-element {
  &.--transparent {
    &[class][class][class][class][class] {
      background: transparent;
      border-color: transparent;
      padding: {
        left: 0;
        right: 0;
      }
    }

    @if $form-element-border-radius {
      border-radius: 0;
    }
  }
}

/**
 * States.
 */

/**
 * Form element, active.
 */

%form-element {
  &.is-active {
    @extend %form-element:focus;
  }
}

/**
 * Form element, disabled.
 */

%form-element {
  &:disabled,
  &.is-disabled {
    border-color: map-get($form-element-border-color, disable);
    color: map-get($form-element-color, disable);
    cursor: not-allowed;
  }
}

/**
 * Form element, focused.
 */

%form-element {
  &.is-focused,
  &:focus {
    &:not(:disabled):not(.is-disabled):not([readonly]):not(.is-readonly) {
      border-color: map-get($form-element-border-color, focus);
      color: map-get($form-element-color, focus);
    }
  }
}

/**
 * Form element, hovered.
 */

%form-element {
  &:hover,
  &.is-hovered {
    &:not(:disabled):not(.is-disabled):not([readonly]):not(.is-readonly) {
      border-color: map-get($form-element-border-color, hover);
    }
  }
}

/**
 * Form element, invalid.
 */

%form-element {
  // &:invalid,
  &.is-invalid {
    background-color: map-get($form-element-background, invalid);
    border-color: map-get($form-element-border-color, invalid);
    color: map-get($form-element-color, invalid);
  }
}

/**
 * Form element, read-only.
 */

%form-element {
  &[readonly],
  &.is-readonly {
    // @extend %form-element:disabled;
  }
}

// =============================================================================
// Subcomponents
// =============================================================================

// SubcomponentName
// =============================================================================

// .component__subcomponent {}
